import { useLocation, useNavigate } from 'react-router-dom'
import '../App.css'
import debticon from '../icons/Vectordebt.svg'
import { useEffect, useState } from 'react';
import PostService from '../API/PostService';
import LessonList from '../components/LessonList';

const tgWebApp = window.Telegram.WebApp;

function StudentDebtPage() {

    const location = useLocation()

    const [lessonCount, setLessonCount] = useState(0)
    const [totalDebt, setTotalDebt] = useState(0)
    const [lessons, setLessons] = useState([])
    const [student, setStudent] = useState({})
    const [avatarSrc, setAvatarSrc] = useState(null)

    const navigate = useNavigate()
    const goBack = () => navigate('/teacher/requests', {replace: true})
    const goReport = () => navigate('/student/report', {state: student})

    const BackButton = tgWebApp.BackButton
    BackButton.show()
    BackButton.onClick(goBack)

    const onPay = (lesson) => {
        setLessons(lessons =>
            lessons.map(l => 
                l.id === lesson.id ? {...l, is_paid: true } : l
            )
        )
    }

    useEffect(()=>{
        const getDebt = async () => {
            const storedData = localStorage.getItem('pickedStudent')
            if (storedData === undefined || storedData === null)
                return
            const pickedStudent = JSON.parse(storedData)
            setStudent(pickedStudent)
            console.log('storedData',storedData);
            console.log('student debt page pickedStudent',pickedStudent);
            console.log('href student debt page',window.location.href.substring(window.location.href.indexOf('student')+8));
            let response = await PostService.getTeacherDebt()
            if ( response.status !== 200)
            {
                console.log(response);
            }
            else
            {
            }
            const debts = response.data

            response = await PostService.getLessonsTeacher()
            if ( response.status !== 200)
            {
                console.log(response);
            }
            else
            {
            }
            const lessons = response.data
            console.log('lessons', lessons);
            let filteredLessons = lessons.filter( (obj) => obj.student_id === pickedStudent.student_id )
            console.log('filteredLessons',filteredLessons);

            console.log(debts);

            response = await PostService.getStudentAvatar(pickedStudent.student_id)
            if ( response.status !==200 )
            {
                
            }
            else
            {
                const base64 = btoa(
                    new Uint8Array(response.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ''
                    )
                )
                setAvatarSrc(`data:image/png;base64,${base64}`)
            }

            const storedLesson = localStorage.getItem('pickedLesson')
            let pickedLesson = null
            if (storedLesson === undefined || storedLesson === null)
                pickedLesson = JSON.parse(storedLesson)
            if ( pickedLesson !== null )
            {
                filteredLessons = filteredLessons =>
                    filteredLessons.map(l => 
                        l.id === pickedLesson.id ? {...l, is_paid: true, status: 'success' } : l
                    )
            }

            setLessonCount(filteredLessons.filter((obj) => obj.status === 'success').length)

            const lessonIds = filteredLessons.filter((obj)=> obj.is_paid === false).map(lesson => lesson.id);

            const totalSum = debts
            .filter(obj => lessonIds.includes(obj.id))
            .reduce((sum, obj) => sum + obj.commission, 0);

            setTotalDebt(totalSum)

            let processedLessons = []
            for ( const lesson of filteredLessons )
            {
                console.log('lesson',lesson);
                const debt = debts.find((o) => o.id === lesson.id)
                console.log('debt',debt);
                let l = {...lesson}
                l.debt = debt?.commission === undefined ? (lesson.price/10) : debt.commission
                console.log('l',l);
                processedLessons.push(l)
            }
            console.log('processedLessons',processedLessons);
            setLessons(processedLessons)
        }

        getDebt()
    },[])

    return (
        <div className='student-debt-page'>
            <img className='avatar-image' src={avatarSrc?avatarSrc:"https://sun9-72.userapi.com/impg/oErJGs22nV9hEBFVaJb265b4mJWkSuQEKSfzQw/8cALmH0jVVE.jpg?size=516x304&quality=96&sign=a857802168baf4230f1521aa91b1745b&type=album"} alt='new' />
            <div className='name'>{student?.name}</div>
            <div className='lessons-counter'>Пройдено занятий: {lessonCount}</div>
            <div className='debt-title'>Задолженность по комиссии: {totalDebt}₽</div>
            {false ? <div className='button-debt'>Оплатить</div> : <></>}
            <div className='button-report' onClick={goReport}>Создать урок <img src={debticon}/></div>
            <div className="texts-body">
                    <div className="science-info">Предмет: <span className="inside-text">{student.science}</span></div>
                    <div className="science-info">Возраст: <span className="inside-text">{student.age}</span></div>
                    <div className="science-info">Цель занятий: <span className="inside-text">{student.target}</span></div>
                    <div className="science-info">Цена ₽: <span className="inside-text">{student.price}</span></div>
                    <div className="science-info">Доп. информация: <span className="inside-text">{student.desc}</span></div>
            </div>
            <LessonList lessons={lessons} isTeacher={true} onPay={onPay}/>
            <div className="button-cancel" >Отказаться от ученика</div>
        </div>
    )
}

export default StudentDebtPage