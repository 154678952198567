import { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import addSubjectIcon from '../icons/Icon add.svg'

function ScienceEditCard({teacher, index, handleScAssoc}) {

    const targetsRef = useRef()
    const agesRef = useRef()
    const statusesRef = useRef()

    const [scienceValue, setScienceValue] = useState({})
    const [targetValues, setTargetValues] = useState([])
    const [ageValues, setAgeValues] = useState([])
    const [statusValues, setStatusValues] = useState([])

    const [experienceSelect, setExperienceSelect] = useState([])

    const [price, setPrice] = useState(0)
    const [experience, setExperience] = useState(0)
    const [sciences, setSciences] = useState([])

    const [scTargets, setScTargets] = useState([])
    const [scAges, setScAges] = useState([])
    const [scStatuses, setScStatuses] = useState([])

    const handleScienceChange = ( selectedOption ) => {
        console.log(selectedOption);
        let value = selectedOption !== null ? selectedOption.value : null
        setScienceValue( selectedOption )
        setAgeValues([])
        targetsRef.current.clearValue()
        agesRef.current.clearValue()
        statusesRef.current.clearValue()
    }

    const handleAgesChange = ( selectedOptions ) => {
        console.log('ages',selectedOptions);
        let values = []
        console.log(values);
        selectedOptions.forEach( option => values.push(option))
        console.log(values);
        setAgeValues(values)
        console.log(values);
        console.log('handleAgesChange',scienceValue, values, targetValues, statusValues);
        
        saveAssoc(scienceValue, values, targetValues, statusValues)
    }

    const handleTargetsChange = ( selectedOptions ) => {
        let values = []
        selectedOptions.forEach( option => values.push(option))
        setTargetValues(values)
        console.log('handleTargetsChange',scienceValue, ageValues, values, statusValues);
        
        saveAssoc(scienceValue, ageValues, values, statusValues)
    }

    const handleStatusesChange = ( selectedOptions ) => {
        if (!selectedOptions)
        {
            setStatusValues([])
            return
        }
        setStatusValues(selectedOptions)
        console.log('handleStatusesChange',scienceValue, ageValues, targetValues, selectedOptions);
        
        saveAssoc(scienceValue, ageValues, targetValues, selectedOptions)
    }

    const saveAssoc = (sc, age, target, status) => {
        let age_ids = []
        console.log(status,typeof(status));
        
        age.forEach( (a) => {
            age_ids.push(a.value)
        })
        let lesson_target_ids = []
        target.forEach( (t) => {
            lesson_target_ids.push(t.value)
        })
        const associationObj = {
            "science_id": sc.value,
            "age_ids": age_ids,
            "lesson_target_ids":lesson_target_ids,
            "teacher_status":status.value,
            "teacher_id": teacher.id
        }
        console.log('associationObj',associationObj);
        handleScAssoc(associationObj, index)
        
    }

    const handlePriceChange = ( event ) => {
        console.log(Math.round(event.target.value));
        setPrice(Math.round(event.target.value))
    }

    const handleExperienceChange = ( selectedOption ) => {
        if (!selectedOption)
            return
        console.log(Math.round(selectedOption.value));
        setExperience(Math.round(selectedOption.value))
    }

    useEffect( () => {
        console.log('scVlaue::::::::::::::::::::',scienceValue);
        if (scienceValue === undefined)
            return

        const scienceObj = JSON.parse(localStorage.getItem('GSciences')).find( sc => sc.id === scienceValue.value )

        if (scienceObj === undefined)
            return

        let scTargetList = []
        scienceObj.targets.forEach( trg => {
            const targetObj = JSON.parse(localStorage.getItem('GTargets')).find( t => t.id === trg )
            scTargetList.push({value:targetObj.id, label:targetObj.name})
        })
        setScTargets(scTargetList)

        let scAgeList = []
        scienceObj.ages.forEach( ag => {
            const ageObj = JSON.parse(localStorage.getItem('GAges')).find( a => a.id === ag)
            scAgeList.push({value:ageObj.id, label:ageObj.name})
        })
        setScAges(scAgeList)

        let scStatusList = []
        scienceObj.statuses.forEach( sts => {
            const statusObj = JSON.parse(localStorage.getItem('GStatuses')).find( s => s.id === sts)
            scStatusList.push({value:statusObj.id, label:statusObj.name})
        })
        setScStatuses(scStatusList)

    }, [scienceValue])

    useEffect(() => {
        const setCard = () => {
            console.log('teacher, index',teacher, index);
            
            const numbers = Array.from({ length: 50 }, (_, index) => index + 1);
            const experienceArr = []
            numbers.forEach( (number) => {
                experienceArr.push({value: number, label: `${number} лет`})
            })

            setExperienceSelect(experienceArr)

            let tempSciences = []
            if (!JSON.parse(localStorage.getItem('GSciences')).length)
                return
            JSON.parse(localStorage.getItem('GSciences')).forEach( sc => 
                tempSciences.push({value:sc.id, label:sc.name})
            )
            if ( sciences.length === 0)
                setSciences(tempSciences)

            const scAssociation = teacher.science_associations[index]
            if (teacher.science_associations[index] === undefined)
                return
            
            let scArray = []
            const scienceObj = JSON.parse(localStorage.getItem('GSciences')).find(e => e.id === scAssociation.science_id)
            const scienceSelect = {value: scienceObj.id, label: scienceObj.name}
            const statusObj = JSON.parse(localStorage.getItem('GStatuses')).find(e => e.id === scAssociation.teacher_status)
            const statusSelect = {value: statusObj.id, label: statusObj.name}
            let ageSelect = []
            scAssociation.age_ids.forEach( (age) => {
                const ageObj = JSON.parse(localStorage.getItem('GAges')).find(e => e.id === age)
                ageSelect.push({value: ageObj.id, label: ageObj.name})
            })
            let targetSelect = []
            scAssociation.lesson_target_ids.forEach( (target) => {
                const targetObj = JSON.parse(localStorage.getItem('GTargets')).find(e => e.id === target)
                targetSelect.push({value: targetObj.id, label: targetObj.name})
            })
            const scObj = {
                science: scienceSelect,
                status: statusSelect,
                age: ageSelect,
                target: targetSelect
            }
            console.log('scObj',scObj);
            
            setScienceValue(scienceSelect)
            setAgeValues(ageSelect)
            setStatusValues(statusSelect)
            setTargetValues(targetSelect)
            saveAssoc(scienceSelect, ageSelect, targetSelect, statusSelect)
        }

        setCard()
    },[teacher])

    return(
        <div className='science-edit-card'>
            <div className='edit-title'>Предмет</div>
            <Select placeholder={'Предмет'} isSearchable={false} onChange={handleScienceChange} value={scienceValue}
            className='science-name select' options={sciences}
            menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
            <div className='edit-title'>Статус</div>
            <Select ref={statusesRef} placeholder={'Статус преподавателя'} isSearchable={false} onChange={handleStatusesChange}
            className='science-status select' options={scStatuses} value={statusValues}
            menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
            <div className='edit-title'>Направления</div>
            <Select ref={targetsRef} placeholder={'Направления подготовки'} isMulti isSearchable={false} onChange={handleTargetsChange}
            isClearable className='science-targets select' options={scTargets} value={targetValues}
            menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
            <div className='edit-title'>Возраст</div>
            <Select ref={agesRef} placeholder={'Возраст учеников'} isMulti isSearchable={false} onChange={handleAgesChange}
            isClearable className='science-ages select' options={scAges} value={ageValues}
            menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
            <div className='edit-title'>Стаж преподавания</div>
            <Select ref={agesRef} placeholder={'Стаж'} isSearchable={false} onChange={handleExperienceChange}
            className='science-ages select' options={experienceSelect} value={experienceSelect[experience]}
            menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
            <div className='edit-title'>Цена ₽/ час</div>
            <input className='teacher-name input' placeholder='Цена' value={price} onChange={handlePriceChange} />
        </div>
    )
}

function ScienceEditCardList({teacher, length, handleScChange}) {

    const handleScAssoc = (scAssociation, index) => {
        handleScChange(scAssociation, index)
        
    }

    const cards = []
    for (let i = 0; i<length; i++)
    {
        cards.push(<ScienceEditCard teacher={teacher} index={i} handleScAssoc={handleScAssoc}/>)
    }

    return <div className='science-edit-card-list'>{cards}</div>
}

export default ScienceEditCardList