import '../App.css'
import icon from '../icons/Iconscheck.svg'

function EditProgressBlock({tabIndex}) {
    return (
        <div className='progress-block'>
            <div className='circle-block'>
                <div className='circle active'>
                    {
                        tabIndex === 1 ?
                        <img src={icon} alt='new' />
                        : <></>
                    }
                </div>
                <div className='title'>1</div>
            </div>
            <div className={tabIndex === 1 ? 'line' : 'line active'}></div>
            <div className='circle-block'>
                <div className={tabIndex > 1 ? 'circle active' : 'circle'}>
                    {
                        tabIndex === 2 ?
                        <img src={icon} alt='new' />
                        : <></>
                    }
                </div>
                <div className='title'>2</div>
            </div>
            <div className={tabIndex === 3 ? 'line active' : 'line'}></div>
            <div className='circle-block'>
                <div className={tabIndex === 3 ? 'circle active' : 'circle'}>
                    {
                        tabIndex === 3 ?
                        <img src={icon} alt='new' />
                        : <></>
                    }
                </div>
                <div className='title'>3</div>
            </div>
        </div>
    )
}

export default EditProgressBlock