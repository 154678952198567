import React, { useState, useEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import PostService from '../API/PostService';
import icon from '../icons/Vectormagn.svg';
import { jsPDF } from 'jspdf';

// Set the workerSrc property to point to the PDF.js worker
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const PDFViewer = ({ certPath }) => {
    const [error, setError] = useState(null);
    const [active, setActive] = useState(false);

    const canvasRef = useRef(null);
    const canvasBigRef = useRef(null);

    const handleCertClicked = () => {
        console.log('handleCertClicked');
        setActive(true);
    };

    useEffect(() => {
        const getFileType = (arrayBuffer) => {
            const bytes = new Uint8Array(arrayBuffer).subarray(0, 8);
            let header = '';
            for (let i = 0; i < bytes.length; i++) {
                header += String.fromCharCode(bytes[i]);
            }

            if (header.startsWith('%PDF-')) {
                return 'pdf';
            } else {
                return 'img';
            }
        };

        const convertImageToPdf = async (rawData) => {
            const pdf = new jsPDF();
            const base64 = btoa(
                new Uint8Array(rawData).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            );
        
            // Create an image object to get the dimensions
            const img = new Image();
            img.src = `data:image/png;base64,${base64}`;
        
            // Ensure the image is loaded before proceeding
            await new Promise((resolve) => {
                img.onload = resolve;
            });
        
            // Get the dimensions of the image
            const imgWidth = img.width;
            const imgHeight = img.height;
            //console.log('width,height:',imgWidth, imgHeight);
            
        
            // Get the dimensions of the PDF page
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
        
            // Calculate the scale factor to fit the image within the PDF page
            const scale = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);
        
            // Calculate the new dimensions
            const newWidth = imgWidth * scale;
            const newHeight = imgHeight * scale;
        
            // Center the image on the page
            const xOffset = (pageWidth - newWidth) / 2;
            const yOffset = (pageHeight - newHeight) / 2;
        
            // Add the image to the PDF with the calculated dimensions
            pdf.addImage(`data:image/png;base64,${base64}`, 'PNG', 0, 0, newWidth, newHeight);
        
            // Get the resulting PDF data
            const pdfData = pdf.output('arraybuffer');
            return pdfData;
        };
        

        const fetchPDF = async () => {
            try {
                const response = await PostService.getTeacherCertificates(certPath);

                if (response.status === 200) {
                    let pdfData = response.data;
                    if (getFileType(pdfData) !== 'pdf') {
                        pdfData = await convertImageToPdf(pdfData);
                    }
                    renderPDF(pdfData);
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            } catch (error) {
                setError(error.message);
                console.error('Error fetching PDF:', error);
            }
        };

        const renderPDF = async (pdfData) => {
            try {
                const loadingTask = pdfjsLib.getDocument({ data: pdfData });
                const pdf = await loadingTask.promise;
                const firstPage = await pdf.getPage(1);

                {
                    const canvas = canvasRef.current;
                    const context = canvas.getContext('2d');
                    const viewport = firstPage.getViewport({ scale: 1.5 });

                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport,
                    };
                    await firstPage.render(renderContext).promise;
                }

                const numPages = pdf.numPages;
                for (let pageNum = 1; pageNum <= numPages; pageNum++) {
                    const page = await pdf.getPage(pageNum);
                    const viewport = page.getViewport({ scale: 1 });
                    
                    const canvas = document.createElement('canvas');
                    canvas.width = viewport.width;
                    canvas.height = viewport.height;
                    canvasBigRef.current.appendChild(canvas);
                    canvasBigRef.current.height += canvas.height;

                    const context = canvas.getContext('2d');

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport,
                    };
                    await page.render(renderContext).promise;
                }

            } catch (error) {
                setError(error.message);
                console.error('Error rendering PDF:', error);
            }
        };

        fetchPDF();

    }, [certPath]);

    return (
        <div className='certificate-container' >
            <canvas ref={canvasRef} onClick={handleCertClicked}></canvas>
            <img src={icon} alt='new' onClick={handleCertClicked} />

            {error && <div className="error-message">Error: {error}</div>}

            <div className={active ? 'modal-container active' : 'modal-container'} onClick={() => setActive(false)}>
                <div className="pdf-content">
                    <div ref={canvasBigRef}></div>
                </div>
            </div>
        </div>
    );
};

export default PDFViewer;
