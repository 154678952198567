import { useNavigate, useParams } from 'react-router-dom'
import '../App.css'
import TeacherReviewsList from '../components/TeacherReviewsList'
import { useState } from 'react'
import PostService from '../API/PostService'
import ModalTeacherReview from '../components/ModalTeacherReview'

function TeacherReviewsPage() {
    const { id } = useParams()
    console.log(id);
    const navigate = useNavigate()

    const [reviews, setReviews] = useState([])
    const [active, setActive] = useState(false)
    const [teacher, setTeacher] = useState({})
    const [canReview, setCanReview] = useState(false)

    const handleReviewAdded = async (review) => {
        console.log('handleReviewAdded review',review);
        const paramObj = {
            'teacher_id': teacher.id,
            'review_text': review.text,
            'review_rating': review.rating
        }
        console.log('handleReviewAdded paramObj',paramObj);
        const response = await PostService.createTeacherReview(paramObj)
        console.log('handleReviewAdded',response);
        if ( response.status !== 200) return

        console.log(response);
        setReviews([...reviews, response.data])
    }

    useState( () => {
        const getReviews = async () => {
            let response = await PostService.getCurrentStudent()
            let flag = true
            if ( response.status !== 200)
            {
                flag = false
                console.log(response);
            }
            response = id === undefined ? await PostService.getTeacherMe() : await PostService.getTeacher(id)
            setCanReview(flag && id !== undefined)
            if ( response.status !== 200)
            {}
            else
            {
            }
            const teacher = response.data
            setTeacher(teacher)
            response = await PostService.getTeacherReviews(teacher.id)
            if ( response.status !== 200)
            {}
            else
            {
            }
            console.log(response.data);
            setReviews(response.data)
        }
        
        getReviews()
    },[reviews])

    return(
        <div className='teacher-reviews-page'>
            <div className='title'>Отзывы</div>
            <TeacherReviewsList reviews={reviews} />
            {
                canReview ?
                <div className='button-review' onClick={() => setActive(true)}>Оставить отзыв</div>
                : <></>
            }
            
            <ModalTeacherReview active={active} setActive={setActive} handleReview={handleReviewAdded} />
        </div>
    )
}

export default TeacherReviewsPage