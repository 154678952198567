import '../App.css'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { useState } from 'react';
import PostService from '../API/PostService';

function OrderCard( { orderInit, onResponse, priceInit } ) {

    const [date, setDate] = useState(Date())
    const [time, setTime] = useState('00:00')
    const [price, setPrice] = useState(priceInit == undefined ? 0 : priceInit)

    const handleClick = ( isAccepted ) => {
        onResponse(isAccepted, orderInit.id)
    }

    const handleDate = ( value ) => {
        console.log(value);
        setDate(value)
    }

    const handleTime = ( event ) => {
        console.log( event.target.value )
        setTime(event.target.value)
    }

    const handlePrice = ( event ) => {
        console.log( event.target.value )
        setPrice(Number(event.target.value))
    }

    const handleLesson = async () => {
        console.log(Date(date), time, typeof(Date(date)));
        let dateTime = new Date(date)
        const hours = Number(time.slice(0,2))
        const minutes = Number(time.slice(3,5))

        dateTime.setHours(hours,minutes,0)
        console.log(dateTime.toJSON(), price);
        const lessonObj = {
            "student_id": orderInit.student_id,
            "date_of_lesson": dateTime.toJSON(),
            "price": price,
            "science_id": orderInit.science_id
        }
        const response = await PostService.createLesson(lessonObj)
        console.log(response);
    }

    return (
        orderInit !== undefined ?
            <div className='order-card'>
                <div className='order-desc'>
                    <div className='order-science'>{orderInit.science_name}</div>
                    <div className='order-age'>{orderInit.age_name}</div>
                    <div className='order-target'>{orderInit.target_name}</div>
                    <div className='order-text'>{orderInit.additional_order_text}</div>
                    <div className='order-created'>{orderInit.created_at}</div>
                    <div className='order-updated'>{orderInit.responsed_at === null ? 'Не отвечен' : orderInit.responsed_at}</div>
                    <div className='order-status'>{orderInit.order_status}</div>
                    { orderInit.order_status === 'approved' ? <div className='order-name'>{orderInit.name === undefined ? '' : orderInit.name}</div> : <></> }
                    { orderInit.order_status === 'approved' ? <div className='order-telegram'>{orderInit.telegram_login}</div> : <></> }
                </div>
                {
                    orderInit.showButtons === 'student' ?
                    <></>
                    : orderInit.showButtons === 'teacher_response' ?
                    <div className='buttonContainer'>
                        <button onClick={() => handleClick(true)} className='order-accept'>Принять</button>
                        <button onClick={() => handleClick(false)} className='order-decline'>Отказаться</button>
                    </div>
                    : <div className='buttonContainer'>
                        <Calendar onChange={handleDate} locale='ru-RU' value={date} />
                        <input type="time" onChange={handleTime} value={time} />
                        <input type='number' onChange={handlePrice} value={price} />
                        <button onClick={handleLesson} className='lesson-create'>Создать урок</button>
                    </div>
                }
                
            </div>
        : <div>empty order</div>
    )
}

export default OrderCard