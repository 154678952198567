import React, { useState } from 'react';
import '../App.css'

const hours = Array.from({ length: 24 }, (_, i) => (i + 1).toString().padStart(2, '0'));
const minutes = Array.from({ length: 60 }, (_, i) => i.toString().padStart(2, '0'));

const TimePicker = ({handleTimeChange}) => {
  const [selectedHour, setSelectedHour] = useState(1);  // Store the selected hour
  const [selectedMinute, setSelectedMinute] = useState(0);  // Store the selected minute

  const handleHour = (h) => {
    console.log(h);
    setSelectedHour(h)
    handleTimeChange(h, selectedMinute)
  }
  
  const handleMinute = (m) => {
    console.log(m);
    setSelectedMinute(m)
    handleTimeChange(selectedHour, m)
  }

  return (
    <div>
      <div className="time-picker">
        <div className="time-picker-wheel">
          <ul>
            {hours.map((hour, index) => (
              <li
                key={index}
                className={selectedHour === index + 1 ? 'selected' : ''}
                onClick={() => handleHour(index + 1)}
              >
                {hour}
              </li>
            ))}
          </ul>
        </div>
        <div className="time-picker-wheel">
          <ul>
            {minutes.map((minute, index) => (
              <li
                key={index}
                className={selectedMinute === index ? 'selected' : ''}
                onClick={() => handleMinute(index)}
              >
                {minute}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TimePicker;