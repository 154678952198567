import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const PaymentForm = ( { token } ) => {
    console.log('payment form token:::::::::::::::::: ', token, window.location.href);
    useEffect(() => {
        // Check if the window.YooMoneyCheckoutWidget is loaded
        console.log('here we are');
    // Initialize the widget
        const checkout = new window.YooMoneyCheckoutWidget({
            confirmation_token: token,
            return_url: window.location.href,
            customization: {
                modal: true,
            },
            error_callback: function(error) {
            console.log(error);
            }
        })

        checkout.render('payment-form');
    }, []);

    return (
        <div>
            <script src="https://yookassa.ru/checkout-widget/v1/checkout-widget.js"></script>
            <Helmet>
                <script src="https://static.yoomoney.ru/checkout-widget.js"></script>
            </Helmet>
            <div id="payment-form"></div>
        </div>
    );
};

export default PaymentForm;
