import '../App.css';
import searchIcon from '../icons/zoom-dynamic-color.svg'
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../hoc/AuthProvider';
import PostService from '../API/PostService';
import TeachersList from '../components/TeachersList';
import MainPageHeader from '../components/MainPageHeader';
import FilterTeachersBlock from '../components/FilterTeachersBlock';
import OrderList from '../components/OrderList';
import LessonList from '../components/LessonList';
import ResponseList from '../components/ResponseList';
import ModalQuiz from '../components/ModalQuiz';
import StoriesCarousel from '../components/StoriesCarousel';
import StartHeader from '../components/StartHeader';
import { Oval } from 'react-loader-spinner'

const tgWebApp = window.Telegram.WebApp;
const isTg = Boolean(tgWebApp?.initData.toString().length)
const isMultiTeacher = false

function MainPage() {
    const [currentUser, setCurrentUser] = useState({})
    const [currentStudent, setCurrentStudent] = useState({})
    const [currentTeacher, setCurrentTeacher] = useState({})
    const [teacherList, setTeacherList] = useState([])
    const [sciencesArr, setSciencesArr] = useState([])
    const [targetsArr, setTargetsArr] = useState([])
    const [agesArr, setAgesArr] = useState([])
    const [statusesArr, setStatusesArr] = useState([])
    const [sortBy, setSortBy] = useState("rating")
    const [experience, setExperience] = useState(0)
    const [sortType, setSortType] = useState("asc")
    const [price, setPrice] = useState(0)
    const [filterObj, setFilterObj] = useState({})
    const [orders, setOrders] = useState([])
    const [processedRequests, setProcessedRequests] = useState([])
    const [responses, setResponses] = useState([])
    const [quizes, setQuizes] = useState([])
    const [modalActiveArr, setModalActiveArr] = useState([])
    const [currentQuiz, setCurrentQuiz] = useState(0)
    const [notifications, setNotifications] = useState(0)
    const [loading, setLoading] = useState(true) // Add loading state
    const [avatarSrc, setAvatarSrc] = useState(null)

    const navigate = useNavigate()
    const { setGOrders } = useContext(AuthContext)

    const goChoicePage = () => navigate(`/choice/`)
    const goRequestsPage = () => {
        if (currentStudent === undefined) return
        navigate(`/student/requests`)
    }
    const goTeacher = () => navigate('/teacher', { replace: true })

    const BackButton = tgWebApp.BackButton
    BackButton.hide()

    const onStudentCreated = async () => {
        const response = await PostService.getCurrentStudent()
        if (response.status !== 200) return
        setCurrentStudent(response.data)
    }

    const onQuizSent = async (quizReport, index) => {
        console.log('onQuizSent', quizReport, index);
        let activeArr = modalActiveArr.slice()
        activeArr[index] = false
        activeArr = activeArr.slice(0, -1)
        const response = await PostService.responseStudentQuiz(quizReport)
        if (response.status !== 200) {
            console.log(response);
        }
        setModalActiveArr(activeArr)
    }

    const processScience = (teacher, science_assoc, sciences, ages, targets, statuses) => {
        const scienceObj = sciences.find(sc => sc.id === science_assoc.science_id)
        if (scienceObj === undefined) return

        let scienceAges = ''
        science_assoc.age_ids.forEach((ageId) => {
            const age = ages.find(age => age.id === ageId)
            scienceAges += age.name + '; '
        })

        let scienceTargets = ''
        science_assoc.lesson_target_ids.forEach((targetId) => {
            const target = targets.find(trg => trg.id === targetId)
            scienceTargets += target.name + '; '
        })

        let scienceStatus = statuses.find(sts => sts.id === science_assoc.teacher_status).name

        return {
            'teacher': teacher,
            'science': scienceObj,
            'ages': scienceAges,
            'targets': scienceTargets,
            'status': scienceStatus
        }
    }

    useEffect(() => {
        const getAvatars = async (teachers) => {
            const processedAvatars = await Promise.all(teachers.map(async (teacher) => {
                try {
                    const avatar = await PostService.getTeacherAvatar(teacher.id);
                    return avatar.data;
                } catch (error) {
                    console.error(`Error fetching avatar for teacher ${teacher.id}:`, error);
                    return undefined; // Fallback in case of error
                }
            }));
            return processedAvatars;
        }

        const getTeachers = async () => {
            console.log('////////////////////////////////////////////// getTeachers start');
            let response = await PostService.getTeacherList(filterObj === undefined ? {} : filterObj, experience, sortBy, sortType, price)
            if (response.status !== 200) {
                console.log(response)
            }
            const teachers = response.data
            const avatarsList = await getAvatars(teachers)

            let processedAvatars = []
            avatarsList.forEach((avatar) => {
                if (avatar === undefined) {
                    processedAvatars.push(undefined)
                } else {
                    const base64 = btoa(
                        new Uint8Array(avatar).reduce(
                            (data, byte) => data + String.fromCharCode(byte),
                            ''
                        )
                    )
                    processedAvatars.push(`data:image/png;base64,${base64}`)
                }
            })

            

            let sciences = sciencesArr
            if (sciencesArr.length === 0) {
                response = await PostService.getSciences()
                if (response.status !== 200) {
                    console.log(response)
                }
                sciences = response.data
            }

            let ages = agesArr
            if (agesArr.length === 0) {
                response = await PostService.getAges()
                if (response.status !== 200) {
                    console.log(response)
                }
                ages = response.data
            }

            let targets = targetsArr
            if (targetsArr.length === 0) {
                response = await PostService.getTargets()
                if (response.status !== 200) {
                    console.log(response)
                }
                targets = response.data
            }

            let statuses = statusesArr
            if (statusesArr.length === 0) {
                response = await PostService.getTeacherStatuses()
                if (response.status !== 200) {
                    console.log(response)
                }
                statuses = response.data
            }

            let processedTeachers = []

            if (teachers === undefined) return
            teachers.forEach((teacher) => {
                if (isMultiTeacher)
                    teacher.science_associations.forEach((science_association) => {
                        processedTeachers.push(processScience(
                            teacher, science_association, sciences, ages, targets, statuses
                        ))
                    })
                else {
                    const science_association = teacher.science_associations[0]
                    processedTeachers.push(processScience(
                        teacher, science_association, sciences, ages, targets, statuses
                    ))
                }
            })

            if (processedAvatars.length !== processedTeachers.length) {
                console.log('avatars not match teachers length')
            }

            processedTeachers.forEach((processedTeacher, index) => {
                processedTeacher.avatar = processedAvatars[index]
            })

            setTeacherList(processedTeachers)

            setSciencesArr(sciences)
            localStorage.setItem('GSciences', JSON.stringify(sciences))
            setStatusesArr(statuses)
            localStorage.setItem('GStatuses', JSON.stringify(statuses))
            setTargetsArr(targets)
            localStorage.setItem('GTargets', JSON.stringify(targets))
            setAgesArr(ages)
            localStorage.setItem('GAges', JSON.stringify(ages))

            console.log('////////////////////////////////////////////// getTeachers end');
        }

        const initUser = async () => {
            setLoading(true); // Set loading to true
            try {
                const response = await PostService.loginTelegram()
                await getCurrentUser()
                await getCurrentStudent()
                await getCurrentTeacher()
                await getTeachers()
            } catch (error) {
                console.error('Error initializing user:', error)
            } finally {
                setLoading(false); // Set loading to false
            }
        }

        const getCurrentUser = async () => {
            const response = await PostService.getCurrentUser()
            if (response.status !== 200) return
            setCurrentUser(response.data)
        }

        const getCurrentStudent = async () => {
            let response = await PostService.getCurrentStudent()
            if (response.status !== 200) return
            setCurrentStudent(response.data)
            console.log('currentStudent for avatar', response.data);
            

            response = await PostService.loginTelegram()
            console.log('loginTelegram for avatar', response.data);
            
            response = await PostService.getMyStudentAvatar() 
            console.log('studentAvatar', response);
            
            if ( response.status !==200 )
            {
                
            }
            else
            {
                const base64 = btoa(
                    new Uint8Array(response.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ''
                    )
                )
                setAvatarSrc(`data:image/png;base64,${base64}`)
            }
        }

        const getCurrentTeacher = async () => {
            const response = await PostService.getTeacherMe()
            if (response.status !== 200) return
            setCurrentTeacher(response.data)
        }
        
        console.log('is telegram? ', isTg)
        if (isTg) {
            tgWebApp?.ready();
            tgWebApp?.expand();
        }

        initUser()
    },[])

    useEffect( () => {
        console.log('whoami',currentStudent, currentTeacher);
        if ( !Object.hasOwn(currentStudent, 'name') && Object.hasOwn(currentTeacher, 'name') )
            goTeacher()
    }, [currentTeacher])

    /*useEffect(()=>{

    }, [currentUser, filterObj])*/

    if (loading) {
        return (
            <div className="spinner-container">
                <div>Загрузка...</div>
                <Oval
                    height={100}
                    width={100}
                    color="#6757F1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#6757F1"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            </div>
        )
    }

    return (
        <div className="main-page">
            {
                Object.hasOwn(currentStudent, 'name') ?
                <MainPageHeader student={currentStudent} avatarSrc={avatarSrc}></MainPageHeader>
                : <StartHeader onStudentCreated={onStudentCreated} />
            }
            {
                true ? <StoriesCarousel isMain={true}/> : <></>
            }
            
            <div className='buttons-container'>
                <div className='requests-container' onClick={goRequestsPage}>
                    <div className='requests-title'>Мои заявки</div>
                    {
                        notifications !== 0 ?
                        <div className='requests-counter'>{notifications}</div>
                        : <></>
                    }
                </div>
                <div className='requests-container' onClick={goChoicePage}>
                    <div className='search-title'>Подобрать репетитора</div>
                    <img src={searchIcon}></img>
                </div>
            </div>
            <div className='list-header'>Список преподавателей</div>
            {
                teacherList !== undefined ?
                    <TeachersList teachersInit={teacherList} listType={'main-page'}></TeachersList>
                : <div>empty teacherList</div>
            }
            {
                false ?
                    <OrderList orders={orders} />
                : <></>
            }
            {
                false ?
                    <OrderList orders={processedRequests} />
                : <></>
            }
            {
                false ?
                <div>
                    {responses.map((rArr, index) =>
                        <ResponseList responses={rArr} />
                    )}
                </div>
                    
                : <></>
            }
            {
                quizes.length !== 0 ?
                quizes.map((quiz, index) =>
                    <ModalQuiz active={modalActiveArr[index]} setActive={onQuizSent} quizData={quiz} index={index} isStudent={true} arrLen={modalActiveArr.length}/>
                )
                : <></>
            }
            {
                false ? 
                <FilterTeachersBlock scienceList={sciencesArr} targetList={targetsArr}
                ageList={agesArr} statusList={statusesArr} /*onFilterChanged={onFilterChanged} onSortChanged={onSortChanged}*/ />
                : <></>
            }
        </div>
    );
}

export default MainPage;
