import '../App.css'
import editIcon from '../icons/edit-student.svg'
import { useNavigate } from 'react-router-dom'

function TeacherPageHeader( {teacher, avatarSrc} ) {

    const navigate = useNavigate()

    const goToEditTeacher = () => navigate('me')
    const goBonusesPage = () => navigate(`/bonuses`)
    
    return (
        <div className="main-page-header">
            <img className="avatar-image" src={avatarSrc ? avatarSrc : 'https://sun9-72.userapi.com/impg/oErJGs22nV9hEBFVaJb265b4mJWkSuQEKSfzQw/8cALmH0jVVE.jpg?size=516x304&quality=96&sign=a857802168baf4230f1521aa91b1745b&type=album'} alt='new' />
            <div className="info-container">
                <div className="header-name">{teacher?.name}</div>
                <div className="student-info">Репетитор</div>
                <div className="edit-container" onClick={goToEditTeacher}>
                    <img src={editIcon}></img>
                    <div className="edit-text">Редактировать</div>
                </div>
            </div>
            
            <div className="bonus-button" onClick={goBonusesPage}>Бонусы</div>
            
        </div>
    )
}

export default TeacherPageHeader