import { useContext, useEffect, useState } from 'react'
import '../App.css'
import { AuthContext } from '../hoc/AuthProvider';
import PostService from '../API/PostService';
import TeachersList from '../components/TeachersList';
import PendingRequestsList from '../components/PendingRequestsList';
import { useNavigate } from 'react-router-dom';
import StudentList from '../components/StudentList';
const tgWebApp = window.Telegram.WebApp;

function TeacherResponsesPage() {

    const navigate = useNavigate()
    const goBack = () => navigate('/teacher', {replace: true})

    const BackButton = tgWebApp.BackButton
    BackButton.show()
    BackButton.onClick(goBack)

    const [ordersStudentList, setOrdersStudentList] = useState([])
    const [myStudentList, setMyStudentList] = useState([])
    const [pendingRequestsList, setPendingRequestsList] = useState([])
    const [tabIndex, setTabIndex] = useState(1)
    const [teacherMe, setTeacherMe] = useState({})

    const handleOrder = (order) => {
        const newArray = ordersStudentList.filter(item => item.id !== order.id);
        setOrdersStudentList(newArray)
    }

    const processOrder = ( order, price, sciences, ages, targets ) => {
        const scienceObj = sciences.find( sc => sc.id === order.science_id )
        console.log('processOrder',order, sciences);

        if ( scienceObj === undefined )
            return

        const age = ages.find( a => a.id === order.age_category_id ).name
        const target = targets.find( t => t.id === order.lessons_target_id ).name

        const date = new Date(order.created_at);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short'
          };
        const formatter = new Intl.DateTimeFormat('ru-RU', options);
        const formattedDate = formatter.format(date);
        
        return (
            {
                'science':scienceObj.name,
                'age': age,
                'target': target, 
                'desc': order.additional_order_text,
                'date': formattedDate,
                'price': price,
                'science_id': order.science_id,
                'id':order.id,
                'student_id': order.student_id
            }
        )
    }

    const processRequest = ( request, sciences, ages, targets ) => {
        const scienceObj = sciences.find( sc => sc.id === request.science_id )

        if ( scienceObj === undefined )
            return

        const age = ages.find( a => a.id === request.age_category ).name

        let scienceTargets = ''
        request.lesson_targets.forEach( (targetId) =>
        {
            const target = targets.find( trg => trg.id === targetId)
            scienceTargets += target.name + '; '
        })
        scienceTargets = scienceTargets.slice(0, scienceTargets.length-2)

        return (
            {
                'science': scienceObj.name, 
                'age': age,
                'target': scienceTargets,
                'desc': request.request_description,
                'price': request.max_price,
                'id': request.id,
                'student_id': request.student_id                
            }
        )
    }

    useEffect(() => {

        const getOrderStudents = async ( orders ) => {
            const processedStudents = await Promise.all(orders.map( async (order) => {
                try 
                {
                    const response = await PostService.getOrderStudent(order.id)
                    return response.data
                }
                catch ( error )
                {
                    console.error('getOrderStudents error',order, error)
                    return undefined
                }
                
            }))

            return processedStudents
        }

        const getRequestStudents = async ( requests ) => {
            const processedStudents = await Promise.all(requests.map( async (request) => {
                try 
                {
                    const response = await PostService.getRequestStudentContact(request.id)
                    return response.data
                }
                catch ( error )
                {
                    console.error('getRequestStudents error',request, error)
                    return undefined
                }
                
            }))

            return processedStudents
        }

        console.log('useeffect ordersStudentList');
        const getStudents = async () =>
        {
            let sciences = []
            let response = await PostService.getSciences()
            if (response.status !== 200) {
                console.log(response)
            }
            sciences = response.data
            

            let ages = []
            response = await PostService.getAges()
            if (response.status !== 200) {
                console.log(response)
            }
            ages = response.data
            

            let targets = []
            response = await PostService.getTargets()
            if (response.status !== 200) {
                console.log(response)
            }
            targets = response.data

            let statuses = []
            response = await PostService.getTeacherStatuses()
            if (response.status !== 200) {
                console.log(response)
            }
            statuses = response.data
            
            localStorage.setItem('GSciences', JSON.stringify(sciences))
            localStorage.setItem('GStatuses', JSON.stringify(statuses))
            localStorage.setItem('GTargets', JSON.stringify(targets))
            localStorage.setItem('GAges', JSON.stringify(ages))

            response = await PostService.getAcceptedRequests()
            if ( response.status !== 200)
            {
                console.log(response);
            }
            else
            {
            }

            let acceptedRequests = response.data
            console.log('getAcceptedRequests',acceptedRequests);

            let processedRequestStudents = await getRequestStudents(acceptedRequests)
            console.log('processedRequestStudents',processedRequestStudents);

            let processedAcceptedRequests = []
            acceptedRequests.forEach( (request) => {
                processedAcceptedRequests.push(processRequest(request,sciences, ages, targets))
            })

            console.log('processedAcceptedRequests',processedAcceptedRequests);

            if ( processedRequestStudents.length !== processedAcceptedRequests.length )
            {
                console.log('length assert')
                return
            }

            console.log('processedRequestStudents',processedRequestStudents);
            for ( let s = 0; s< processedRequestStudents.length; s++)
            {
                processedAcceptedRequests[s].name = processedRequestStudents[s].name
                processedAcceptedRequests[s].student_id = processedRequestStudents[s].student_id
            }
            console.log('processedAcceptedRequests',processedAcceptedRequests);

            response = await PostService.getTeacherOrders()
            if ( response.status !== 200)
            {
                console.log(response);
            }
            else
            {
            }

            const studentsIds = response.data
            console.log('studentsIds',studentsIds);
            let answeredOrders = studentsIds?.filter( (obj) => obj.order_status === 'approved' )

            let processedStudents = await getOrderStudents(answeredOrders)
            console.log('processedStudents',processedStudents);

            let processedAnswered = []
            answeredOrders.forEach( (order) => {
                processedAnswered.push(processOrder(order, teacherMe.price,sciences, ages, targets))
            })
            console.log('processedAnswered',processedAnswered);

            if ( processedStudents.length !== processedAnswered.length )
            {
                console.log('length assert');
                return
            }
            
            console.log('processedAnswered',processedAnswered);
            for ( let s = 0; s< processedStudents.length; s++)
            {
                if (processedStudents[s] === undefined)
                    continue
                processedAnswered[s].name = processedStudents[s].name
                processedAnswered[s].student_id = processedStudents[s].student_id
            }

            let studentList = processedAnswered.concat(processedAcceptedRequests)
            console.log('studentList',studentList); 
            setMyStudentList(studentList)
        }

        getStudents()

    },[ordersStudentList])

    useEffect( () => {

        const getOrderStudents = async ( orders ) => {
            const processedStudents = await Promise.all(orders.map( async (order) => {
                try 
                {
                    const response = await PostService.getOrderStudent(order.id)
                    return response.data
                }
                catch ( error )
                {
                    console.error('getOrderStudents error',order, error)
                    return undefined
                }
                
            }))

            return processedStudents
        }

        const getRequestStudents = async ( requests ) => {
            const processedStudents = await Promise.all(requests.map( async (request) => {
                try 
                {
                    const response = await PostService.getRequestStudentContact(request.id)
                    return response.data
                }
                catch ( error )
                {
                    console.error('getRequestStudents error',request, error)
                    return undefined
                }
                
            }))

            return processedStudents
        }

        const getOrders = async () => {

            let sciences = []
            let response = await PostService.getSciences()
            if (response.status !== 200) {
                console.log(response)
            }
            sciences = response.data
            

            let ages = []
            response = await PostService.getAges()
            if (response.status !== 200) {
                console.log(response)
            }
            ages = response.data
            

            let targets = []
            response = await PostService.getTargets()
            if (response.status !== 200) {
                console.log(response)
            }
            targets = response.data

            let statuses = []
            response = await PostService.getTeacherStatuses()
            if (response.status !== 200) {
                console.log(response)
            }
            statuses = response.data
            
            localStorage.setItem('GStatuses', JSON.stringify(statuses))
            localStorage.setItem('GTargets', JSON.stringify(targets))
            localStorage.setItem('GAges', JSON.stringify(ages))

            response = await PostService.getTeacherMe()

            if ( response.status !== 200)
            {}
            else
            {
            }
            const teacher = response.data
            setTeacherMe(teacher)

            response = await PostService.getTeacherOrders()
            if ( response.status !== 200)
            {
                console.log(response);
            }
            else
            {
            }

            const studentsIds = response.data

            response = await PostService.getSuitableRequests()
            if ( response.status !== 200)
            {
                console.log(response);
            }
            else
            {
            }

            const suitableRequests = response.data

            let unansweredOrders = studentsIds?.filter( (obj) => obj.order_status === 'waiting' )
            let answeredOrders = studentsIds?.filter( (obj) => obj.order_status === 'approved' )

            response = await PostService.getAcceptedRequests()
            if ( response.status !== 200)
            {
                console.log(response);
            }
            else
            {
            }

            let acceptedRequests = response.data
            console.log('.........................................................');

            let processedRequestStudents = await getRequestStudents(acceptedRequests)

            let processedAcceptedRequests = []
            acceptedRequests.forEach( (request) => {
                processedAcceptedRequests.push(processRequest(request, sciences, ages, targets))
            })
            
            if ( processedRequestStudents.length !== processedAcceptedRequests.length )
            {
                console.log('length assert')
                return
            }

            console.log('processedRequestStudents',processedRequestStudents);
            for ( let s = 0; s< processedRequestStudents.length; s++)
            {
                processedAcceptedRequests[s].name = processedRequestStudents[s].name
                processedAcceptedRequests[s].student_id = processedRequestStudents[s].student_id
            }
            console.log('processedAcceptedRequests',processedAcceptedRequests);

            let processedStudents = await getOrderStudents(answeredOrders)

            let processedUnanswered = []
            unansweredOrders.forEach( (order ) => {
                processedUnanswered.push(processOrder(order, teacher.price,sciences, ages, targets))
            })

            let processedAnswered = []
            answeredOrders.forEach( (order) => {
                processedAnswered.push(processOrder(order, teacher.price,sciences, ages, targets))
            })

            if ( processedStudents.length !== processedAnswered.length )
            {
                console.log('length assert');
                return
            }
            
            console.log('processedAnswered',processedAnswered);
            for ( let s = 0; s< processedStudents.length; s++)
            {
                processedAnswered[s].name = processedStudents[s].name
                processedAnswered[s].student_id = processedStudents[s].student_id
            }
            console.log('processedAnswered',processedAnswered);
            console.log('///////////////////////////////////////////////////////////////');

            if ( ordersStudentList.length === 0 )
                setOrdersStudentList(processedUnanswered)
            let studentList = processedAnswered.concat(processedAcceptedRequests)
            console.log('studentList',studentList); 
            setMyStudentList(studentList)

            let processedRequests = []
            suitableRequests.forEach( (request) => {
                processedRequests.push(processRequest(request,sciences, ages, targets))
            })
            setPendingRequestsList(processedRequests)
        }

        getOrders()
        
    }, [])
    
    return (
        <div className='teacher-responses-page'>
            <div className='teacher-response-header'>Мои заявки</div>
            <div className='teacher-responses-title'>
                <div className={tabIndex === 1 ? 'active-tab': ''} onClick={()=>setTabIndex(1)}>Заявки</div>
                <div className={tabIndex === 2 ? 'active-tab': ''} onClick={()=>setTabIndex(2)}>Запросы</div>
                <div className={tabIndex === 3 ? 'active-tab': ''} onClick={()=>setTabIndex(3)}>Мои ученики</div>
            </div>
            <div className={tabIndex === 1 ? 'content active-content': 'content' } >
                <div className='list-description'>Тут отображаются заявки учеников, которые выбрали вас напрямую. Контакты появятся после того как вы примете заявку.</div>
                {
                    ordersStudentList.length !== 0 ? 
                    <StudentList students={ordersStudentList} listType={'orders'} onClick={handleOrder}/>
                    :<></>
                }
            </div>
            <div className={tabIndex === 2 ? 'content active-content': 'content' }>
                <div className='list-description'>Тут отображаются заявки учеников, которые возможно вам подойдут.</div>
                {
                    pendingRequestsList.length !== 0 ?
                    <StudentList students={pendingRequestsList} listType={'requests'} />
                    :<></>
                }
            </div>
            <div className={tabIndex === 3 ? 'content active-content': 'content' }>
                <div className='list-description'></div>
                {
                    myStudentList.length !== 0 ? 
                    <StudentList students={myStudentList} listType={'my'}/>
                    :<></>
                }
            </div>
        </div>
    )
}

export default TeacherResponsesPage