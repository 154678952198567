import { useNavigate } from 'react-router-dom'
import '../App.css'
import starIcon from '../icons/Star 2.svg'

function TeacherCard( {cardInit, listType} ) {

    const navigate = useNavigate()

    const goTeacherPage = () => navigate(`/teacher/${cardInit.teacher.id}`)

    return (
        cardInit !== undefined ?
            <div className='teacher-card'>
                <div className='teacher-info'>
                    <div className='image-container'>
                        <img className='teacher-image' src={cardInit.avatar ? cardInit.avatar : 'https://sun9-72.userapi.com/impg/oErJGs22nV9hEBFVaJb265b4mJWkSuQEKSfzQw/8cALmH0jVVE.jpg?size=516x304&quality=96&sign=a857802168baf4230f1521aa91b1745b&type=album'} alt='new' />
                        <div className='teacher-rating'>
                            <div>{cardInit.teacher.rating}</div>
                            <img src={starIcon}/>
                        </div>
                    </div>
                    <div className='teacher-details'>
                        <div className='teacher-name'>{cardInit.teacher.name}</div>
                        <div className='teacher-science'>{cardInit.science.name}</div>
                        <div className='teacher-cost'>Стоимость: {cardInit.teacher.price} руб/час</div>
                        <div className='teacher-experience'>Стаж: {cardInit.teacher.experience}</div>
                    </div>
                </div>
                <div className='texts-body'>
                        <div className='teacher-targets'>
                            Направления: <span className='inside-text'>{cardInit.targets}</span>
                        </div>
                        <div className='teacher-ages'>
                            Возрастные категории: <span>{cardInit.ages}</span>
                        </div>
                </div>
                <div className={listType !== 'orders' ? 'teacher-button' : 'teacher-button disabled'} onClick={listType !== 'orders' ? goTeacherPage : null}>{listType !== 'orders' ? `Подробнее` : `Отменить`}</div>
            </div>
            : <></>
    )
}

export default TeacherCard