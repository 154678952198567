import editIcon from '../icons/edit-student.svg'
import '../App.css'
import { useNavigate } from 'react-router-dom'
import { useRef, useState } from 'react'
import PostService from '../API/PostService'

function StudentOverviewHeader( { student, avatarSrc } ) {

    const navigate = useNavigate()
    const goUpdatePage = () => navigate(`/student/update`)

    return (
        <div className='main-page-header'>
            <img className="avatar-image" src={avatarSrc ? avatarSrc : 'https://sun9-72.userapi.com/impg/oErJGs22nV9hEBFVaJb265b4mJWkSuQEKSfzQw/8cALmH0jVVE.jpg?size=516x304&quality=96&sign=a857802168baf4230f1521aa91b1745b&type=album'} alt='new' />
            <div className="info-container">
                <div className="header-name">{student.name}</div>
                <div className="edit-container" onClick={goUpdatePage}>                    
                    <div className="edit-text">Редактировать профиль</div>
                    <img src={editIcon}></img>
                </div>
            </div>
        </div>
    )
}

export default StudentOverviewHeader