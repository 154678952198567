import { useState } from 'react'
import '../App.css'
import TextareaAutosize from 'react-textarea-autosize'
import StarRatings from 'react-star-ratings';

const ModalTeacherReview = ( { active, setActive, handleReview } ) => {

    const [text, setText] = useState('')
    const [rating, setRating] = useState(0)

    const handleReviewChanged = ( event ) => {
        setText(event.target.value)
    }

    const handleRatingChanged = ( event ) => {
        console.log(event);
        setRating(event)
    }

    const handleSendClicked = () => {
        const objReview = {
            'rating': rating,
            'text': text
        }
        handleReview(objReview)
        setActive(false)
    }

    return (
        <div className={active ? 'modal-container active' : 'modal-container'} onClick={() => setActive(false)}>
            <div className='teacher-review-content' onClick={e => e.stopPropagation()}>
                <div className="modal-title">Отзыв</div>
                <TextareaAutosize className='modal-area' onChange={handleReviewChanged} />
                <StarRatings
                    rating={rating}
                    changeRating={handleRatingChanged}
                    starRatedColor="#f6da43"
                    numberOfStars={5}
                    starHoverColor='#f6da43'
                    starDimension='36px'
                    starSpacing='0px'
                />
                <div className='button-send' onClick={handleSendClicked}>Отправить</div>
            </div>
        </div>
    )
}

export default ModalTeacherReview