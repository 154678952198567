import { useState, useEffect, useRef } from "react"
import PostService from "../API/PostService"
import Select from 'react-select'
import TextareaAutosize from 'react-textarea-autosize'

function AddPage() {
    
    const targetsRef = useRef()
    const agesRef = useRef()
    const statusesRef = useRef()

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [nickname, setNickname] = useState('')
    const [id, setId] = useState('')
    const [display, setDisplay] = useState(false)

    const [sciences, setSciences] = useState([])

    const [scTargets, setScTargets] = useState([])
    const [scAges, setScAges] = useState([])
    const [scStatuses, setScStatuses] = useState([])

    const [scienceValue, setScienceValue] = useState({})
    const [targetValues, setTargetValues] = useState([])
    const [ageValues, setAgeValues] = useState([])
    const [statusValues, setStatusValues] = useState([])

    const [price, setPrice] = useState(0)
    const [description, setDescription] = useState("")
    const [experience, setExperience] = useState(0)

    const [experienceSelect, setExperienceSelect] = useState([])

    const handleScienceChange = ( selectedOption ) => {
        console.log(selectedOption);
        let value = selectedOption !== null ? selectedOption.value : null
        setScienceValue( selectedOption )
        setAgeValues([])
        targetsRef.current.clearValue()
        agesRef.current.clearValue()
        statusesRef.current.clearValue()
    }

    const handleAgesChange = ( selectedOptions ) => {
        console.log('ages',selectedOptions);
        let values = []
        console.log(values);
        selectedOptions.forEach( option => values.push(option))
        console.log(values);
        setAgeValues(values)
        console.log(values);
    }

    const handlePriceChange = ( event ) => {
        console.log(Math.round(event.target.value));
        setPrice(Math.round(event.target.value))
    }

    const handleDescriptionChange = ( event ) => {
        setDescription(event.target.value)
    }

    const handleTargetsChange = ( selectedOptions ) => {
        let values = []
        selectedOptions.forEach( option => values.push(option))
        setTargetValues(values)
    }

    const handleStatusesChange = ( selectedOptions ) => {
        if (!selectedOptions)
        {
            setStatusValues([])
            return
        }
        setStatusValues(selectedOptions)
    }

    const handleExperienceChange = ( selectedOption ) => {
        if (!selectedOption)
            return
        console.log(Math.round(selectedOption.value));
        setExperience(Math.round(selectedOption.value))
    }

    const loginTeacher = async () => {
        console.log('name:',name,'nickname:',nickname,'id:',id)
        const user = {
            id: id,
            first_name: name,
            last_name: surname,
            username: nickname
        };
        
        const userJson = JSON.stringify(user);
        const encodedUser = encodeURIComponent(userJson);
        const randomValues = new Uint8Array(16);
        crypto.getRandomValues(randomValues);
        
        //const queryString = `query_id=${'AAEXyzMXAAAAABfLMxcrngEI'}&user=${encodedUser}`;
        const queryString = {'WebAppInitData':`query_id=AAEXyzMXAAAAABfLMxcrngEI&user=%7B%22id%22%3A${id}%2C%22first_name%22%3A%22${name}%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22${nickname}%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1719954567&hash=4ba3570b2f0a40da4e095ce7bc702a78c5debb08271946f488a57304efb2e972`}
        console.log(queryString);
        
        let response = await PostService.loginTelegramFromInitData(queryString)
        console.log('loginTelegramFromInitData',response);
        response = await PostService.getCurrentUser()
        console.log('whoami', response)
        setDisplay(true)
    }

    const setTeacherData = async () => {
        /*const science_id = scienceValue.value
        const teacher_status = statusValues.value
        let age_ids = []
        ageValues.forEach( (a) => {
            age_ids.push(a.value)
        })
        let lesson_target_ids = []
        targetValues.forEach( (t) => {
            lesson_target_ids.push(t.value)
        })
        const associationObj = [{
            "science_id": science_id,
            "age_ids": age_ids,
            "lesson_target_ids":lesson_target_ids,
            "teacher_status":teacher_status,
            "teacher_id": currentTeacher.id
        }]
        let teacherObject = {}
        if ( science_id === undefined )
            teacherObject = {
                "name": name,
                "experience":experience,
                "about_me":description,
                "price":price,
                "is_visible":currentTeacher.is_visible
            }
        else
            teacherObject = {
                "name":name,
                "science_associations": associationObj,
                "experience":experience,
                "about_me":description,
                "price":price,
                "is_visible":currentTeacher.is_visible
            }

        if (( science_id === undefined || teacher_status === undefined || lesson_target_ids.length === 0 || age_ids.length === 0 ))
            return
        console.log('teacherObj',teacherObject);*/
        
    }

    const handleNameChange = (e) => {
        setName(e.target.value)
    }

    const handleSurnameChange = (e) => {
        setSurname(e.target.value)
    }

    const handleNicknameChange = (e) => {
        setNickname(e.target.value)
    }

    const handleIdChange = (e) => {
        setId(e.target.value)
    }

    useEffect( () => {
        console.log('scVlaue::::::::::::::::::::',scienceValue);
        if (scienceValue === undefined)
            return

        const scienceObj = JSON.parse(localStorage.getItem('GSciences')).find( sc => sc.id === scienceValue.value )

        if (scienceObj === undefined)
            return

        let scTargetList = []
        scienceObj.targets.forEach( trg => {
            const targetObj = JSON.parse(localStorage.getItem('GTargets')).find( t => t.id === trg )
            scTargetList.push({value:targetObj.id, label:targetObj.name})
        })
        setScTargets(scTargetList)

        let scAgeList = []
        scienceObj.ages.forEach( ag => {
            const ageObj = JSON.parse(localStorage.getItem('GAges')).find( a => a.id === ag)
            scAgeList.push({value:ageObj.id, label:ageObj.name})
        })
        setScAges(scAgeList)

        let scStatusList = []
        scienceObj.statuses.forEach( sts => {
            const statusObj = JSON.parse(localStorage.getItem('GStatuses')).find( s => s.id === sts)
            scStatusList.push({value:statusObj.id, label:statusObj.name})
        })
        setScStatuses(scStatusList)

    }, [scienceValue])

    useEffect( () => {
        const getSc = async () => {
            let tempSciences = []
            if (!JSON.parse(localStorage.getItem('GSciences')).length)
                return
            JSON.parse(localStorage.getItem('GSciences')).forEach( sc => 
                tempSciences.push({value:sc.id, label:sc.name})
            )
            if ( sciences.length === 0)
                setSciences(tempSciences)
        }

        getSc()
    },[])


    return(
        <div className="add-page">
            <input className='input' placeholder='Имя' value={name} onChange={handleNameChange} />
            {/*<input className='input' placeholder='Фамилия' value={surname} onChange={handleSurnameChange} />*/}
            <input className='input' placeholder='Ник' value={nickname} onChange={handleNicknameChange} />
            <input className='input' placeholder='ID' value={id} onChange={handleIdChange} />
            <button onClick={loginTeacher} className='approve'>Добавить</button>
            {
                display ?
                <div>
                    <div className='edit-title'>Предмет</div>
                    <Select placeholder={'Предмет'} isSearchable={false} onChange={handleScienceChange} value={scienceValue}
                    className='science-name select' options={sciences}
                    menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    <div className='edit-title'>Статус</div>
                    <Select ref={statusesRef} placeholder={'Статус преподавателя'} isSearchable={false} onChange={handleStatusesChange}
                    className='science-status select' options={scStatuses} value={statusValues}
                    menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    <div className='edit-title'>Направления</div>
                    <Select ref={targetsRef} placeholder={'Направления подготовки'} isMulti isSearchable={false} onChange={handleTargetsChange}
                    isClearable className='science-targets select' options={scTargets} value={targetValues}
                    menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    <div className='edit-title'>Возраст</div>
                    <Select ref={agesRef} placeholder={'Возраст учеников'} isMulti isSearchable={false} onChange={handleAgesChange}
                    isClearable className='science-ages select' options={scAges} value={ageValues}
                    menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    <div className='edit-title'>Стаж преподавания</div>
                    <Select ref={agesRef} placeholder={'Стаж'} isSearchable={false} onChange={handleExperienceChange}
                    className='science-ages select' options={experienceSelect} value={experienceSelect[experience]}
                    menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                    <div className='edit-title'>Цена ₽/ час</div>
                    <input className='teacher-name input' placeholder='Цена' value={price} onChange={handlePriceChange} />
                    <TextareaAutosize value={description} className='teacher-description'
                    placeholder='Расскажите о себе, укажите свой опыт, подходы в преподавание, результаты ваших учеников прошлых лет.'
                    onChange={handleDescriptionChange}/>
                    <button onClick={loginTeacher} className='approve'>Добавить учителя</button>
                </div>
                : <></>
            }
        </div>
    )
}

export default AddPage