import { useState } from 'react'
import '../App.css'
import ModalStudentRegister from './ModalStudentRegister'
import ModalTeacherRegister from './ModalTeacherRegister'

function StartHeader( { onStudentCreated } ) {

    const [studentModal, setStudentModal] = useState(false)
    const [teacherModal, setTeacherModal] = useState(false)

    return (
        <div className='start-header'>
            <div className='start-button' onClick={() => setTeacherModal(!teacherModal)}>
                Войти как репетитор
            </div>
            <div className='start-button' onClick={() => setStudentModal(!studentModal)}>
                Войти как ученик
            </div>
            <ModalStudentRegister active={studentModal} setActive={setStudentModal} onStudentCreated={onStudentCreated} />
            <ModalTeacherRegister active={teacherModal} setActive={setTeacherModal} />
        </div>
    )
}

export default StartHeader