import PostService from '../API/PostService';
import '../App.css'

function PendingRequestsList( {requests, onCancel} ) {
    return (
        <div className='requests-list'>
            { requests !== undefined && requests.length !== 0 ?
                <div>
                    {requests.map((request, index) => 
                        <RequestCard request={request} onCancel={onCancel} />
                    )}
                </div>
                :<></>
            }
        </div>
    )
}

export default PendingRequestsList

function RequestCard( {request, onCancel} ) {

    const handleClick = async () => {
        console.log(request.id);
        const paramObj = {
            id: request.id,
            is_active: false
        }
        onCancel(request)
        const response = await PostService.updateTeacherRequest(paramObj)
        if ( response.status !== 200 )
        {
            console.log(response);
            return
        }
        console.log(response.data);
    }

    return (
        <div className='request-card'>
            <div className='header'>Ищем репетитора в нашей дополнительной базе. В течение 3-5 дней пришлём ответ в Telegram</div>
            <div className='title'>Предмет: <span>{request.science}</span></div>
            <div className='title'>Возраст: <span>{request.age}</span></div>
            <div className='title'>Цель занятий: <span>{request.targets}</span></div>
            <div className='title'>Цена до ₽: <span>{request.price}</span></div>
            <div className='title'>Статус репетитора: <span>{request.statuses}</span></div>
            <div className='title'>Доп. информация: <span>{request.science}</span></div>
            <div className='button-cancel' onClick={handleClick}>Отменить запрос</div>
        </div>
    )
}