import { useLocation, useNavigate } from 'react-router-dom'
import '../App.css'

const tgWebApp = window.Telegram.WebApp;

function StoryPage() {
    const location = useLocation()
    const pageData = location.state
    console.log(`pageLink`,pageData);

    const navigate = useNavigate()
    const goBack = () => navigate(pageData.isMain ? '/' : '/teacher', {replace: true})

    const BackButton = tgWebApp.BackButton
    BackButton.show()
    BackButton.onClick(goBack)

    return(
        <div style={{ width: '100%', height: '100vh' }}>
            <iframe 
                src={pageData.link} 
                style={{ width: '100%', height: '100%', border: 'none' }} 
                title="External Page"
            />
        </div>
    )
}

export default StoryPage