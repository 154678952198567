import editIcon from '../icons/edit-student.svg'
import '../App.css'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import PostService from '../API/PostService'

function MainPageHeader( { student, avatarSrc } ) {

    const navigate = useNavigate()

    const goStudentPage = () => navigate(`/student/me`)
    const goBonusesPage = () => navigate(`/bonuses`)

    return (
        <div className="main-page-header">
            <img className="avatar-image" src={avatarSrc ? avatarSrc : 'https://sun9-72.userapi.com/impg/oErJGs22nV9hEBFVaJb265b4mJWkSuQEKSfzQw/8cALmH0jVVE.jpg?size=516x304&quality=96&sign=a857802168baf4230f1521aa91b1745b&type=album'} alt='new' />
            <div className="info-container">
                <div className="header-name">{student.name}</div>
                <div className="student-info">Ученик</div>
                <div className="edit-container">
                    <img src={`${editIcon}`}></img>
                    <div className="edit-text" onClick={goStudentPage}>Редактировать</div>
                </div>
            </div>
            
            <div className="bonus-button" onClick={goBonusesPage}>Бонусы</div>
        </div>
    )
}

export default MainPageHeader