import TeacherCard from './TeacherCard'

function TeachersList( { teachersInit, listType } ) {
    return (
        <div className='teachers-list'>
            { teachersInit !== undefined && teachersInit.length !== 0 ?
                <div>
                    {teachersInit.map((teacher, index) => 
                        <TeacherCard cardInit={teacher} listType={listType}/>
                    )}
                </div>
                : <div>empty teachers</div>
            }

        </div>
    )
}

export default TeachersList