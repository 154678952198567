import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const baseUrl = 'https://test.repetitmeweb.ru/'
//const baseUrl = 'http://localhost:8000/'
const jsonHeaders = { 'Accept': 'application/json', 'Content-Type': 'application/json' }
const fileHeaders = { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data' }

const defaultParams = { headers: jsonHeaders, withCredentials: true }
const fileParams = { headers: fileHeaders, withCredentials: true }

//const initDataTest = {"WebAppInitData":false ? "query_id=AAEXyzMXAAAAABfLMxdNrlk_&user=%7B%22id%22%3A389270295%2C%22first_name%22%3A%22krafstof%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22krafstof%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1710600929&hash=124c1bf04916eb5d0e5298f26f4ddec5d85d8aa5c6a2fb4f83895fe82e8a204c"
//    : "query_id%3DAAG8RFUZBBBBALxEVdfZoFwT%26user%3D%7B%22id%22%3A425123456%2C%22first_name%22%3A%22Alaaaa%22%2C%22last_name%22%3A%22Ceeee%22%2C%22username%22%3A%22isadsafdaa%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D%26auth_date%3D1717525934%26hash%3Dcc47e57051cc2c531ad42162134faef9669b406a5aae3c128da758c94d79d16c"}
const initDataTest = {"WebAppInitData":true ? "query_id=AAEXyzMXAAAAABfLMxcrngEI&user=%7B%22id%22%3A389270295%2C%22first_name%22%3A%22krafstof%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22krafstof%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1719954567&hash=4ba3570b2f0a40da4e095ce7bc702a78c5debb08271946f488a57304efb2e972"
      //: "query_id%3DAAG8RFUZBBABALxEVdfZoFwT%26user%3D%7B%22id%22%3A425128956%2C%22first_name%22%3A%22Another%22%2C%22last_name%22%3A%22Teacher%22%2C%22username%22%3A%22namenamename%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D%26auth_date%3D1717525934%26hash%3Dcc47e57051cc2c531bf32162134faef9669b406a5aae3c128da758c94d79d16c"}
      : "query_id=AAEkCskNAAAAACQKyQ1yKR_O&user=%7B%22id%22%3A231279140%2C%22first_name%22%3A%22Stamka%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22Ssstamka%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1719569977&hash=f673cb6969c49c29d6bb924b632cef698b9785fdf7b2d450ba0cf5d3b9eb1a74"}
const tgWebApp = window.Telegram.WebApp;
const initData = tgWebApp?.initData.toString().length ? {"WebAppInitData":tgWebApp?.initData} : initDataTest
console.log('postservice',tgWebApp?.initData.toString());

export default class PostService {
    
    //логинимся в телеге на старте
    static async loginTelegram()
    {
        const url = baseUrl + 'auth/login_telegram'
        console.log(`loginTelegram`,initData);
        try 
        {
            const response = await axios.post( url, initData, defaultParams )
            console.log(`response loginTelegram`,response);
            return response
        }
        catch ( error )
        {
            console.error()
            return error
        }
    }

    static async logoutTelegram()
    {
        const url = baseUrl + 'auth/logout'
        try 
        {
            const response = await axios.post( url, defaultParams )
            console.log(`response logout`,response);
            return response
        }
        catch ( error )
        {
            console.error()
            return error
        }
    }

    static async loginTelegramFromInitData(paramObj)
    {
        const url = baseUrl + 'auth/login_telegram'
        console.log(`loginTelegram`,paramObj);
        try 
        {
            const response = await axios.post( url, paramObj, defaultParams )
            console.log(`response loginTelegram`,response);
            return response
        }
        catch ( error )
        {
            console.error()
            return error
        }
    }

    static async refreshToken()
    {
        const url = baseUrl + 'auth/refresh'
        try 
        {
            const response = await axios.post( url, defaultParams )
            return response
        }
        catch ( error )
        {
            console.error()
            return error
        }
    }

    static async getCurrentUser()
    {
        const url = baseUrl + 'users/me'
        try 
        {
            const response = await axios.get( url, defaultParams )
            return response
        }
        catch ( error )
        {
            console.error()
            return error;
        }
    }

    static async getCurrentStudent()
    {
        const url = baseUrl + 'student/me'
        try 
        {
            const response = await axios.get( url, defaultParams )
            return response
        }
        catch ( error )
        {            
            console.error()
            return error;
        }
    }

    static async getRefCode()
    {
        const url = baseUrl + 'referral_code/generate_referral_code'
        try 
        {
            const response = await axios.get( url, defaultParams )
            return response
        }
        catch ( error )
        {            
            console.error()
            return error;
        }
    }

    static async registerRefCode(refCode)
    {
        const url = baseUrl + `referral_code/register_new_referral?referral_code=${refCode}`
        try
        {
            const response = await axios.post(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async updateCurrentUserName(name){
        const url = baseUrl + 'users/me'
        try
        {
            const response = await axios.put( url, name,  defaultParams )
            return response
        }
        catch( error )
        {
            console.error()
            return error;
        }
    }

    static async getTeacherList(params, experience, sort_by, sort_type, price)
    {
        const url = baseUrl + `teacher/list?experience=${experience}&max_price=${price}&sort_by=${sort_by}&sort_type=${sort_type}&offset=0&limit=100`
        try
        {
            const response = await axios.post( url, params, defaultParams )
            return response
        }
        catch ( error )
        {
            console.error()
            return error;
        }
    }

    static async getTeacherMe()
    {
        const url = baseUrl + 'teacher/me'
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch ( error )
        {            
            console.error()
            return error;
        }
    }

    static async getTeacher(params)
    {
        const url = baseUrl + `teacher/info?teacher_id=${params}`
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async getSciences()
    {
        const url = baseUrl + 'teacher/sciences'
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async getTargets()
    {
        const url = baseUrl + 'teacher/lesson_targets'
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async getAges()
    {
        const url = baseUrl + 'teacher/ages'
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async getTeacherStatuses()
    {
        const url = baseUrl + 'teacher/teacher_statuses'
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async becomeTeacher(params)
    {
        const url = baseUrl + 'teacher/become_teacher'
        try
        {
            const response = await axios.post(url, params, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async becomeStudent(params)
    {
        const url = baseUrl + 'student/become_student'
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async updateTeacher(params)
    {
        const url = baseUrl + 'teacher/me'
        try
        {
            const response = await axios.put(url, params, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async updateStudent(params)
    {
        const url = baseUrl + `student/me`
        try
        {
            const response = await axios.put(url, params, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async makeOrder(params)
    {
        const url = baseUrl + `orders/`
        try
        {
            const response = await axios.post(url, params, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async getTeacherOrders()
    {
        const url = baseUrl + 'orders/teacher'
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async getStudentOrders()
    {
        const url = baseUrl + 'orders/student'
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async responseOrder(params)
    {
        const url = baseUrl + `orders/response`
        try
        {
            const response = await axios.post(url, params, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async getOrderStudent(id)
    {
        const url = baseUrl + `orders/${id}/student`
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async cancelOrder(id)
    {
        const url = baseUrl + `orders/cancel?order_id=${id}`
        try
        {
            const response = await axios.post(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async createLesson(params)
    {
        const url = baseUrl + `lessons/teacher`
        try
        {
            const response = await axios.post(url, params, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async getLessonsStudent()
    {
        const url = baseUrl + 'lessons/student'
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async getLessonsTeacher()
    {
        const url = baseUrl + 'lessons/teacher'
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async createTeacherRequest(params)
    {
        const url = baseUrl + `teacher_request/student`
        try
        {
            const response = await axios.post( url, params, defaultParams )
            return response
        }
        catch ( error )
        {
            console.error()
            return error;
        }
    }

    static async updateTeacherRequest(params)
    {
        const url = baseUrl + `teacher_request/student`
        try
        {
            const response = await axios.patch( url, params, defaultParams )
            return response
        }
        catch ( error )
        {
            console.error()
            return error;
        }
    }

    static async getSuitableRequests()
    {
        const url = baseUrl + 'teacher_request/suitable_for_teacher'
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async getStudentRequests()
    {
        const url = baseUrl + 'teacher_request/student'
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async getAcceptedRequests()
    {
        const url = baseUrl + 'teacher_request/accepted'
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async getRequestStudentContact(id)
    {
        const url = baseUrl + `teacher_request/student_contacts?teacher_request_id=${id}`
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async getStudentTeacherRequests(id)
    {
        const url = baseUrl + `teacher_request_response/student?teacher_request_id=${id}`
        try
        {
            const response = await axios.get(url, {}, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async createResponseToTeacherRequest(id)
    {
        const url = baseUrl + `teacher_request_response/?teacher_request_id=${id}`
        try
        {
            const response = await axios.post( url, {}, defaultParams )
            return response
        }
        catch ( error )
        {
            console.error()
            return error;
        }
    }

    static async answerToTeacherRequestResponse(params, id)
    {
        const url = baseUrl + `teacher_request_response/student?teacher_request_id=${id}`
        try
        {
            const response = await axios.post( url, params, defaultParams )
            return response
        }
        catch ( error )
        {
            console.error()
            return error;
        }
    }

    static async getStudentQuiz()
    {
        const url = baseUrl + `teacher_student_quiz/student`
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async getTeacherQuiz()
    {
        const url = baseUrl + `teacher_student_quiz/teacher`
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async responseStudentQuiz(params)
    {
        const url = baseUrl + `teacher_student_quiz/student`
        try
        {
            const response = await axios.put( url, params, defaultParams )
            return response
        }
        catch ( error )
        {
            console.error()
            return error;
        }
    }

    static async responseTeacherQuiz(params)
    {
        const url = baseUrl + `teacher_student_quiz/teacher`
        try
        {
            const response = await axios.put( url, params, defaultParams )
            return response
        }
        catch ( error )
        {
            console.error()
            return error;
        }
    }

    static async getTeacherDebt()
    {
        const url = baseUrl + `payment/debt`
        try
        {
            const response = await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error
        }
    }

    static async makePaymentByLessonId(lesson_id)
    {
        const url = baseUrl + `payment/lesson?lesson_id=${lesson_id}`
        try
        {
            const response = await axios.post(url, {}, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async uploadTeacherAvatar(params)
    {
        const url = baseUrl + `teacher/me/upload_avatar`
        try
        {
            const response = await axios.post(url, params, fileParams)
            return response
        }
        catch ( error )
        {
            console.error()
            return error
        }
    }

    static async uploadStudentAvatar(params)
    {
        const url = baseUrl + `student/me/upload_avatar`
        try
        {
            const response = await axios.post(url, params, fileParams)
            return response
        }
        catch ( error )
        {
            console.error()
            return error
        }
    }

    static async getMyStudentAvatar()
    {
        const url = baseUrl + `student/me/avatar`
        try
        {
            const response = await axios.get(url, { responseType: 'arraybuffer', withCredentials: true })
            return response
        }
        catch ( error )
        {
            console.error()
            return error
        }
    }

    static async getStudentAvatar(id)
    {
        const url = baseUrl + `student/avatar?student_id=${id}`
        try
        {
            const response = await axios.get(url, { responseType: 'arraybuffer', withCredentials: true })
            return response
        }
        catch ( error )
        {
            console.error()
            return error
        }
    }

    static async getTeacherAvatar(id)
    {
        const url = baseUrl + `teacher/avatar?teacher_id=${id}`
        try
        {
            const response = await axios.get(url, {responseType: 'arraybuffer'})
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async uploadTeacherVideo(params)
    {
        const url = baseUrl + `teacher/me/upload_video`
        try
        {
            const response = await axios.post(url, params, fileParams)
            return response
        }
        catch ( error )
        {
            console.error()
            return error
        }
    }

    static async getTeacherVideo(id)
    {
        const url = baseUrl + `teacher/video?video_path=${id}`
        try
        {
            const response = await axios.get(url, {
                responseType: 'arraybuffer',
                headers: {
                  'Accept': 'blob',
                },
            });
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async deleteTeacherVideo()
    {
        const url = baseUrl + `teacher/me/video`
        try
        {
            const response = await axios.delete(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async uploadTeacherCertficate(params)
    {
        const url = baseUrl + `teacher/me/upload_certificates`
        try
        {
            const response = await axios.post(url, params, fileParams)
            return response
        }
        catch ( error )
        {
            console.error()
            return error
        }
    }

    static async getTeacherCertificates(id)
    {
        const url = baseUrl + `teacher/certificate?certificate_path=${id}`
        try
        {
            const response = await axios.get(url, {
                responseType: 'arraybuffer',
                /*headers: {
                    'Accept': 'application/pdf',
                },*/
            });
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async getTeacherReviews(id)
    {
        const url = baseUrl + `teacher_review/${id}`
        try
        {
            const response = await await axios.get(url, defaultParams)
            return response
        }
        catch (error)
        {
            console.error()
            return error;
        }
    }

    static async createTeacherReview(params)
    {
        const url = baseUrl + 'teacher_review'
        try 
        {
            const response = await axios.post( url, params, defaultParams )
            return response
        }
        catch ( error )
        {
            console.error()
            return error
        }
    }
}