import { useEffect, useState, useRef } from 'react'
import Select from 'react-select'
import PostService from '../API/PostService'

const sortOptions = [
    { value: 'price', label: 'Цена'},
    { value: 'experience', label: 'Опыт'},
    { value: 'rating', label: 'Рейтинг'}
]

function FilterTeachersBlock( { scienceList, targetList, ageList, statusList, onFilterChanged, onSortChanged } ) {

    const [focus, setFocus] = useState(0)

    const [sciences, setSciences] = useState([])

    const [scTargets, setScTargets] = useState([])
    const [scAges, setScAges] = useState([])
    const [scStatuses, setScStatuses] = useState([])

    const [scienceValue, setScienceValue] = useState()
    const [targetValues, setTargetValues] = useState([])
    const [ageValues, setAgeValues] = useState([])
    const [statusValues, setStatusValues] = useState([])

    const [sortOption, setSortOption] = useState(sortOptions[0])
    const [sortBy, setsortBy] = useState('asc')
    const [experience, setExperience] = useState(0)
    const [price, setPrice] = useState(0)

    const targetsRef = useRef()
    const agesRef = useRef()
    const statusesRef = useRef()

    const handlesortBy = () => {
        sortBy === 'asc' ? setsortBy('desc') : setsortBy('asc')
        onSortChanged(sortOption.value, experience, sortBy === 'asc' ? 'desc' : 'asc', price)
    }

    const handleSortChange = (selectedOption ) => {
        setSortOption(selectedOption)
        onSortChanged(selectedOption.value, experience, sortBy, price)
    }

    const handleExperienceChange = ( event ) => {
        setExperience(Math.round(event.target.value))
        onSortChanged(sortOption.value, Math.round(event.target.value), sortBy, price)
    }

    const handlePriceChange = ( event ) => {
        setPrice(Math.round(event.target.value))
        onSortChanged(sortOption.value, experience, sortBy, Math.round(event.target.value))
    }

    const handleScienceChange = ( selectedOption ) => {
        let value = selectedOption !== null ? selectedOption.value : null
        setScienceValue( value )
        targetsRef.current.clearValue()
        agesRef.current.clearValue()
        statusesRef.current.clearValue()
        onFilterChanged(value, [], [], [])
    }

    const handleTargetsChange = ( selectedOptions ) => {
        let values = []
        selectedOptions.forEach( option => values.push(option.value))
        setTargetValues(values)
        if (focus === 0)
            onFilterChanged(scienceValue, values, ageValues, statusValues)
    }

    const handleAgesChange = ( selectedOptions ) => {
        let values = []
        selectedOptions.forEach( option => values.push(option.value))
        setAgeValues(values)
        if (focus === 0)
            onFilterChanged(scienceValue, targetValues, values, statusValues)
    }

    const handleStatusesChange = ( selectedOptions ) => {
        let values = []
        selectedOptions.forEach( option => values.push(option.value))
        setStatusValues(values)
        if (focus === 0)
            onFilterChanged(scienceValue, targetValues, ageValues, values)
    }

    const handleCreateRequest = async () => {
        const requestObj = {
            "science_id": scienceValue,
            "lesson_targets": targetValues,
            "age_category": ageValues[0],
            "teacher_statuses": statusValues,
            "max_price": price
          }
          console.log(requestObj);

          const response = await PostService.createTeacherRequest(requestObj)
          console.log(response)
    }

    useEffect( () => {
        const scienceObj = scienceList.find( sc => sc.id === scienceValue )

        if (scienceObj === undefined)
            return

        let scTargetList = []
        scienceObj.targets.forEach( trg => {
            const targetObj = targetList.find( t => t.id === trg )
            scTargetList.push({value:targetObj.id, label:targetObj.name})
        })
        setScTargets(scTargetList)

        let scAgeList = []
        scienceObj.ages.forEach( ag => {
            const ageObj = ageList.find( a => a.id === ag)
            scAgeList.push({value:ageObj.id, label:ageObj.name})
        })
        setScAges(scAgeList)

        let scStatusList = []
        scienceObj.statuses.forEach( sts => {
            const statusObj = statusList.find( s => s.id === sts)
            scStatusList.push({value:statusObj.id, label:statusObj.name})
        })
        setScStatuses(scStatusList)

    }, [scienceValue])

    useEffect( () => {
        if ( scienceList.length === 0 )
            return

        let tempSciences = []
        scienceList.forEach( sc => 
            tempSciences.push({value:sc.id, label:sc.name})
        )
        if ( sciences.length === 0)
            setSciences(tempSciences)
        
    }, [scienceList,sciences])

    return (
        <div className="filter-teacher-block">
            <Select placeholder={'Дисциплина'} isSearchable={false} onChange={handleScienceChange} isClearable
            className='science-name select' options={sciences} onFocus={() => setFocus(1)} onBlur={() => setFocus(0)}
            menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
            <Select ref={targetsRef} placeholder={'Направления'} isMulti isSearchable={false} onChange={handleTargetsChange}
            isClearable className='science-targets select' options={scTargets}
            menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
            <Select ref={agesRef} placeholder={'Возрастные группы'} isMulti isSearchable={false} onChange={handleAgesChange}
            isClearable className='science-ages select' options={scAges}
            menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
            <Select ref={statusesRef} placeholder={'Статус преподавателя'} isMulti isSearchable={false} onChange={handleStatusesChange}
            isClearable className='science-status select' options={scStatuses}
            menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
            <div className='sort-container'>
                <Select options={sortOptions} value={sortOption} isSearchable={false} onChange={handleSortChange}
                menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                <button onClick={handlesortBy}>{sortBy === 'asc' ? 'Возр' : 'Убыв'}</button>
                <input className='experience-input' type='number' placeholder='Опыт' value={''+experience} onChange={handleExperienceChange}/>
                <input className='price-input' type='number' placeholder='Стоимость' value={''+price} onChange={handlePriceChange}/>
                <button onClick={handleCreateRequest}>Создать запрос</button>
            </div>
        </div>
    )
}

export default FilterTeachersBlock