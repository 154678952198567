import { useState } from "react"
import '../App.css'
import PostService from "../API/PostService"
import { useNavigate } from "react-router-dom"

const ModalTeacherRegister = ( { active, setActive, onTeacherCreated } ) => {

    const navigate = useNavigate()
    const goTeacher = () => navigate('/teacher', {replace: true})

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [refString, setRefString] = useState('')

    const handleClick = async () => {
        console.log(name + ' ' + surname);
        const fullname = name + ' ' + surname
        const paramObj = {
            "name": fullname,
            "experience": 0,
            "about_me": "",
            "price": 0,
            "is_visible": false
        }
        let response = await PostService.becomeTeacher(paramObj)
        if (response.status !== 200)
        {
            console.log(response);   
        }
        console.log(response);
        response = await PostService.registerRefCode(refString)
        if (response.status !== 200)
        {
            console.log(response);
        }
        setActive(false)
        goTeacher()
    }

    return (
        <div className={active ? 'modal-container active' : 'modal-container'} onClick={() => setActive(false)}>
            <div className="student-content" onClick={e => e.stopPropagation()}>
                <div className="modal-title">Имя</div>
                <input className="modal-input" value={name} onChange={e => setName(e.target.value)}/>
                <div className="modal-title">Фамилия</div>
                <input className="modal-input" value={surname} onChange={e => setSurname(e.target.value)}/>
                <div className="modal-title">{`Реферальная ссылка (при наличии)`}</div>
                <input className="modal-input" value={refString} onChange={e => setRefString(e.target.value)}/>
                <div className="accept-button" onClick={handleClick}>Войти</div>
                <div className="terms">Нажимая «Войти» вы соглашаетесь с <span className="underline">политикой конфиденциальности</span> и <span className="underline">пользовательским соглашением</span></div>
            </div>
        </div>
    )
}

export default ModalTeacherRegister