import '../App.css'
import searchIcon from '../icons/zoom-dynamic-color.svg'
import blackBracketIcon from '../icons/blackbracket.svg'
import coloredBracketIcon from '../icons/coloredbracket.svg'
import chatIcon from '../icons/chat-bubble-dynamic-color.svg'
import { useNavigate } from 'react-router-dom'
const tgWebApp = window.Telegram.WebApp;

function PickTeacherMenuPage() {

    const navigate = useNavigate()

    const goBack = () => navigate('/', {replace: true})

    const BackButton = tgWebApp.BackButton
    BackButton.show()
    BackButton.onClick(goBack)
    const goFilterPage = () => navigate(`/filter/`)

    return (
        <div className='pick-teacher-page'>
            <div className='pick-teacher-title'>Как подобрать репетитора?</div>
            <div className='filter-container' onClick={goFilterPage}>
                <div className='icon-container'>
                    <img className="icon" src={searchIcon}></img>
                    <img className='bracket' src={coloredBracketIcon}></img>
                </div>
                <div className='title'>Подобрать по критериям</div>
            </div>
            <div className='contact-container'>
                <div className='icon-container'>
                    <img className="icon" src={chatIcon}></img>
                    <img className='bracket' src={blackBracketIcon}></img>
                </div>
                <div className='title'>Связаться с консультантом</div>
            </div>
        </div>
    )
}

export default PickTeacherMenuPage