import { useContext, useEffect, useState } from 'react'
import '../App.css'
import { AuthContext } from '../hoc/AuthProvider';
import PostService from '../API/PostService';
import TeachersList from '../components/TeachersList';
import PendingRequestsList from '../components/PendingRequestsList';
import { useNavigate } from 'react-router-dom';
const tgWebApp = window.Telegram.WebApp;

function StudentResponsesPage() {

    const [ordersTeacherList, setOrdersTeacherList] = useState([])
    const [myTeacherList, setMyTeacherList] = useState([])
    const [pendingRequestsList, setPendingRequestsList] = useState([])
    const [orders, setOrders] = useState([])
    const [tabIndex, setTabIndex] = useState(1)
    const [answeredRequests, setAnsweredRequests] = useState([])
    
    const navigate = useNavigate()

    const goBack = () => navigate('/', {replace: true})

    const BackButton = tgWebApp.BackButton
    BackButton.show()
    BackButton.onClick(goBack)

    const { setGTeachers } = useContext(AuthContext)

    const processScience = ( teacher, science_assoc ) => {
        const scienceObj = JSON.parse(localStorage.getItem('GSciences')).find( sc => sc.id === science_assoc.science_id )

        if ( scienceObj === undefined )
            return

        let scienceAges = ''
        science_assoc.age_ids.forEach( (ageId) =>
        {
            const age = JSON.parse(localStorage.getItem('GAges')).find( age => age.id === ageId)
            scienceAges += age.name + '; '
        })

        let scienceTargets = ''
        science_assoc.lesson_target_ids.forEach( (targetId) =>
        {
            const target = JSON.parse(localStorage.getItem('GTargets')).find( trg => trg.id === targetId)
            scienceTargets += target.name + '; '
        })

        let scienceStatus = JSON.parse(localStorage.getItem('GStatuses')).find( sts => sts.id === science_assoc.teacher_status).name

        return {
            'teacher': teacher,
            'science': scienceObj,
            'ages': scienceAges,
            'targets': scienceTargets,
            'status': scienceStatus
        }
    }

    const handleRequest = ( req ) => {
        console.log('handle', req);
        const newArray = pendingRequestsList.filter(item => item.id !== req.id);
        setPendingRequestsList(newArray);
    }

    const processRequest = ( req ) => {
        const scienceObj = JSON.parse(localStorage.getItem('GSciences')).find( sc => sc.id === req.science_id )
        if ( scienceObj === undefined )
            return

        const scienceName = scienceObj.name
        const ageName = JSON.parse(localStorage.getItem('GAges')).find( age => age.id === req.age_category).name

        let targetName = ""
        req.lesson_targets.forEach( (t) => {
            targetName+= JSON.parse(localStorage.getItem('GTargets')).find( trg => trg.id === t).name + '; '
        })
        targetName = targetName.slice(0,targetName.length-2)

        let statusName = ""
        req.teacher_statuses.forEach( (s) => {
            statusName+= JSON.parse(localStorage.getItem('GStatuses')).find( st => st.id === s).name + '; '
        })
        statusName = statusName.slice(0,statusName.length-2)
        return {
            'science': scienceName,
            'age': ageName,
            'targets': targetName,
            'statuses': statusName,
            'price': req.max_price.toString(),
            'id': req.id
        }
    }

    useEffect( () => {

    }, [pendingRequestsList, myTeacherList, ordersTeacherList])

    useEffect( () => {

        const getTeachers = async ( filteredTeachers ) => {
            let processedTeachers = []
            try {
                const promises = filteredTeachers.map( async (teacher) => {
                    const response = await PostService.getTeacher(teacher.teacher_id)
                    const data = response.data
                    processedTeachers.push(data)
                })

                const results = await Promise.all(promises)
            }
            catch ( err )
            {
                console.log(err);
            }
            return processedTeachers
        }

        const getOrders = async () => {
            let response = await PostService.getCurrentStudent()

            if ( response.status !== 200)
            {}
            else
            {
            }
            const currentStudentId = response.data.id

            response = await PostService.getStudentOrders()
            if ( response.status !== 200)
            {}
            else
            {
            }
            const filteredTeachersIds = response.data//.filter((obj) => obj.student_id === currentStudentId)//.map((obj) => obj.teacher_id);
            console.log('filteredTeachersIds', filteredTeachersIds);
            response = await PostService.getStudentRequests()
            if ( response.status !== 200)
            {}
            else
            {
            }
            console.log('getStudentRequests', response);
            let pendingRequests = response.data.filter( (obj) => obj.is_active === true )
            let processedRequests = []
            pendingRequests.forEach( (req) => {
                processedRequests.push(processRequest(req))
            })
            setPendingRequestsList(processedRequests)

            //let answRequests = response.data.filter( (obj) => obj.is_active )

            let filteredTeachers = await getTeachers(filteredTeachersIds)

            console.log(`filteredTeachers`,filteredTeachers);
            if ( filteredTeachers.length !== filteredTeachersIds.length )
            {
                console.log('length assert');
                return
            }
            
            let ordersTeachers = []
            for ( let t = 0; t< filteredTeachersIds.length; t++ )
            {
                if ( filteredTeachersIds[t].order_status !== 'approved')
                    ordersTeachers.push(filteredTeachers[t])
                //console.log(ordersTeachers.length-1, filteredTeachersIds[t]);
                if ( ordersTeachers.length-1 > 0 )
                    ordersTeachers[ordersTeachers.length-1].science_id = filteredTeachersIds[t].science_id
            }
            console.log(`ordersTeachers`,ordersTeachers);

            let processedOrderTeachers = []
            ordersTeachers.forEach( (teacher) =>  {
                const science_association = teacher.science_associations.find(sc => sc.science_id === teacher.science_id)
                processedOrderTeachers.push(processScience(teacher, science_association))
            })

            let myTeachers = []
            for ( let t = 0; t< filteredTeachersIds.length; t++ )
            {
                if ( filteredTeachersIds[t].order_status === 'approved')
                    myTeachers.push(filteredTeachers[t])
            }

            let processedMyTeachers = []
            myTeachers.forEach( (teacher) =>  {
                const science_association = teacher.science_associations[0]
                processedMyTeachers.push(processScience(teacher, science_association))
            })

            setOrdersTeacherList(processedOrderTeachers)
            setMyTeacherList(processedMyTeachers)
            setGTeachers(processedMyTeachers)
        }

        getOrders()
    }, [])

    

    return (
        <div className='student-responses-page'>
            <div className='student-response-header'>Мои заявки</div>
            <div className='student-responses-title'>
                <div className={tabIndex === 1 ? 'active-tab': ''} onClick={()=>setTabIndex(1)}>Заявки</div>
                <div className={tabIndex === 2 ? 'active-tab': ''} onClick={()=>setTabIndex(2)}>Мой репетитор</div>
                <div className={tabIndex === 3 ? 'active-tab': ''} onClick={()=>setTabIndex(3)}>Запросы</div>
            </div>
            <div className={tabIndex === 1 ? 'content active-content': 'content' } >
                {
                    ordersTeacherList !== undefined ?
                    <TeachersList teachersInit={ordersTeacherList} listType={`orders`} ></TeachersList>
                    : <div>empty ordersTeacherList</div>
                }
            </div>
            <div className={tabIndex === 2 ? 'content active-content': 'content' }>
                {
                    myTeacherList !== undefined ?
                    <TeachersList teachersInit={myTeacherList} listType={`my`} ></TeachersList>
                    : <div>empty myTeacherList</div>
                }
                
            </div>
            <div className={tabIndex === 3 ? 'content active-content': 'content' }>
                {
                    pendingRequestsList !== undefined ?
                    <PendingRequestsList requests={pendingRequestsList} listType={`requests`} onCancel={handleRequest} />
                    : <div>empty pendingRequests</div>
                }
            </div>
            
        </div>
    )
}

export default StudentResponsesPage