import { useState } from 'react'
import '../App.css'
import PostService from '../API/PostService'

const ModalStudentRegister = ( { active, setActive, onStudentCreated } ) => {

    const [name, setName] = useState('')

    const handleClick = async () => {
        let response = await PostService.becomeStudent()
        if (response.status !== 200)
        {
            console.log(response);   
        }
        const studObj = { "name":name }
        response = await PostService.updateStudent(studObj)
        if (response.status !== 200)
        {
            console.log(response);
        }
        onStudentCreated()
        setActive(false)
    }

    return (
        <div className={active ? 'modal-container active' : 'modal-container'} onClick={() => setActive(false)}>
            <div className="student-content" onClick={e => e.stopPropagation()}>
                <div className="modal-title">Имя</div>
                <input className="modal-input" value={name} onChange={e => setName(e.target.value)}/>
                <div className="modal-title">{`Реферальная ссылка (при наличии)`}</div>
                <input className="modal-input" value={`https://www.figma.com/designvfbL`}/>
                <div className="accept-button" onClick={handleClick}>Войти</div>
                <div className="terms">Нажимая «Войти» вы соглашаетесь с <span className="underline">политикой конфиденциальности</span> и <span className="underline">пользовательским соглашением</span></div>
            </div>
        </div>
    )
}

export default ModalStudentRegister