import '../App.css'

import { useContext, useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import PostService from '../API/PostService'
import TextareaAutosize from 'react-textarea-autosize'
import { AuthContext } from '../hoc/AuthProvider'
import EditProgressBlock from '../components/EditProgressBlock'
import PDFViewer from '../components/PdfViewer'
import binIcon from '../icons/trash.svg'
import addDiplomaIcon from '../icons/adddiploma.svg'
import addSubjectIcon from '../icons/Icon add.svg'
import VideoPlayer from '../components/VideoPlayer'
import ScienceEditCard from '../components/ScienceEditCard'
import ScienceEditCardList from '../components/ScienceEditCard'

const tgWebApp = window.Telegram.WebApp;

function TeacherEditPage() {

    const navigate = useNavigate()

    const goBack = () => navigate('/teacher/me', {replace: true})

    const BackButton = tgWebApp.BackButton
    BackButton.show()
    BackButton.onClick(goBack)

    const [currentTeacher, setCurrentTeacher] = useState({})

    const fileInputRef = useRef()

    const videoInputRef = useRef();
    const diplomaInputRef = useRef()

    const [name, setName] = useState("")
    const [price, setPrice] = useState(0)
    const [description, setDescription] = useState("")
    const [experience, setExperience] = useState(0)
    const [avatarSrc, setAvatarSrc] = useState(null)
    const [videoUrl, setVideoUrl] = useState('')
    const [certificatesArr, setCertificatesArr] = useState([])

    const [tabIndex, setTabIndex] = useState(1)
    const [scienceLength, setScienceLength] = useState(0)

    const [sciencesList, setSciencesList] = useState([])

    const handleDescriptionChange = ( event ) => {
        setDescription(event.target.value)
    }

    const handleNameChange = ( event ) => {
        console.log(event.target.value);
        setName(event.target.value)
    }

    const handleImageClick = () => {
        fileInputRef.current.click()
    }

    const handleVideoClick = () => {
        videoInputRef.current.click()
    }

    const handleVideoDelete = async () => {
        const response = await PostService.deleteTeacherVideo()
        if ( response.status !== 200 )
        {
            console.log(response);
            return
        }
        setVideoUrl('')
    }

    const handleDiplomaClick = () => {
        diplomaInputRef.current.click()
    }

    const handleSubjectClick = () => {
        console.log('new subject');
        if (scienceLength<3)
            setScienceLength(scienceLength+1)
    }

    const handleAvatarUpload = async ( e ) => {
        console.log(e.target.files);
        if ( e.target.files.length !== 1 )
            return
        console.log(';lllllllllllllllll');
        //const image = URL.createObjectURL(e.target.files[0]).replace('blob:','')
        const image = e.target.files[0]
        if (!image.type.startsWith('image/'))
            return
        const formData = new FormData()
        formData.append('teacher_avatar', image)
        console.log(formData);
        const response = await PostService.uploadTeacherAvatar(formData)
        if ( response.status !== 200 )
        {
            console.log(response);
            return
        }
        const reader = new FileReader();
        reader.onload = () => {
            const base64 = btoa(
                new Uint8Array(reader.result).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            )
            setAvatarSrc(`data:image/png;base64,${base64}`)
        }
        reader.readAsArrayBuffer(image);
    }

    const handleVideoUpload = async ( e ) => {
        if ( e.target.files.length !== 1 )
            return

        const video = e.target.files[0]
        console.log(`video`, video);
        const formData = new FormData()
        formData.append('teacher_video', video)
        const response = await PostService.uploadTeacherVideo(formData)
        console.log('video response',response);
        if ( response.status !== 200 )
        {
            console.log(response);
            return
        }
        const blob = new Blob([response.data], { type: 'video/mp4' });
        const url = URL.createObjectURL(blob);
        setVideoUrl(response.data.video_presentation_link);
    }

    const handleDiplomaUpload = async ( e ) => {
        if ( e.target.files.length === 0 )
            return

        const formData = new FormData()
        for ( const diploma of e.target.files )
        {
            formData.append('teacher_certficates', diploma)
        }
        
        const response = await PostService.uploadTeacherCertficate(formData)
        console.log(`handleDiplomaUpload`,response);
        if ( response.status !== 200 )
        {
        }
        let teacher = {...currentTeacher}
        teacher.certificates = response.data
        setCurrentTeacher(teacher)
    }

    const handleScChange = (scAssoc, index) => {
        console.log('handleScChange',scAssoc, index);
        let updatedList = [...sciencesList]
        if (sciencesList.length<=index || sciencesList.length === 0)
            updatedList.push(scAssoc)
        else
        {
            updatedList[index]=scAssoc
        }
        console.log(updatedList);
        
        setSciencesList(updatedList)
    }

    const saveChanges = async () => {
        let teacherObject = {}
        if ( sciencesList.length === 0 )
            teacherObject = {
                "name": name,
                "experience":experience,
                "about_me":description,
                "price":price,
                "is_visible":currentTeacher.is_visible
            }
        else
            teacherObject = {
                "name":name,
                "science_associations": sciencesList,
                "experience":experience,
                "about_me":description,
                "price":price,
                "is_visible":currentTeacher.is_visible
            }

        console.log('teacherObj',teacherObject);
        const response = await PostService.updateTeacher(teacherObject)
        if ( response.status !==200 )
        {
            return
        }
        if (tabIndex === 3)
            goBack()
        else
            setTabIndex(tabIndex+1)
    }

    useEffect(()=>{

    },[tabIndex])

    useEffect(()=>{

    },[avatarSrc])

    useEffect(()=>{

    },[currentTeacher])

    useEffect(()=>{
        console.log('video url has changed', videoUrl);
        
    },[videoUrl])

    useEffect( () => {
        const getTeacher = async () => {

            let response = await PostService.getTeacherMe()

            if ( response.status !==200 )
            {
                return
            }
            setCurrentTeacher(response.data)
            setName(response.data.name)
            setPrice(response.data.price)
            setDescription(response.data.about_me)
            setExperience(response.data.experience)
            setCertificatesArr(response.data.certificates)
            setScienceLength(response.data.science_associations.length)

            const teacher = response.data

            response = await PostService.getTeacherAvatar(response.data.id)
            if ( response.status !==200 )
            {
                return
            }
            console.log(response);
            const base64 = btoa(
                new Uint8Array(response.data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            )

            if ( teacher.video_presentation_link !== undefined )
            {
                console.log('video_presentation_link', teacher.video_presentation_link);
                setVideoUrl(teacher.video_presentation_link);
            }
            setAvatarSrc(`data:image/png;base64,${base64}`)
        }
        
        getTeacher()

    }, [])

    return (
        <div className='teacher-edit-page'>
            <EditProgressBlock tabIndex={tabIndex} />
            <div className={tabIndex === 1 ? 'content active-content': 'content' } >
                <div className='picture-container'>
                    <img className="avatar-image" src={`${avatarSrc}`} alt='new' onClick={handleImageClick}/>
                </div>
                <div className='picture-container'>
                <input
                    type="file"
                    ref={fileInputRef}
                    accept="image/gif, image/jpeg, image/png, image/heic"
                    style={{ display: 'none' }}
                    onChange={handleAvatarUpload}
                />
                </div>
                <div className='edit-title'>Имя</div>
                <input className='teacher-name input' placeholder='Имя' value={name} onChange={handleNameChange} />
            </div>
            <div className={tabIndex === 2 ? 'content active-content': 'content' } >
                <ScienceEditCardList teacher={currentTeacher} length={scienceLength} handleScChange={handleScChange}/>
                <div className='add-subject' onClick={handleSubjectClick}>Добавить предмет<img src={addSubjectIcon}/></div>
            </div>
            <div className={tabIndex === 3 ? 'content active-content': 'content' } >
                <div className='edit-title'>Обо мне</div>
                <TextareaAutosize value={description} className='teacher-description'
                    placeholder='Расскажите о себе, укажите свой опыт, подходы в преподавание, результаты ваших учеников прошлых лет.'
                    onChange={handleDescriptionChange}
                />
                <div className='edit-title'>Видеопрезентация</div>
                {videoUrl && (
                    <VideoPlayer videoPath={videoUrl} />
                )}
                <div className='video-input' >
                    <div className='button-choose' onClick={handleVideoClick}>Выбрать файл</div>
                    <div className='hint'>MPEG-4, MOV, 3GPP</div>
                    <img src={binIcon} onClick={handleVideoDelete}/>
                    <input type='file' style={{ display: 'none' }} ref={videoInputRef} onChange={handleVideoUpload} accept="video/mp4,video/x-m4v,video/*"/>
                </div>
                
                <div className='edit-title'>Дипломы и сертификаты</div>
                <div className='diplomas-list'>
                    {
                        certificatesArr !== undefined && certificatesArr.length !== 0 ?
                            <div>
                                {certificatesArr.map((cert, index) =>
                                    <PDFViewer certPath={cert} />
                                )}
                                <div className='upload' onClick={handleDiplomaClick}>
                                    <img src={addDiplomaIcon}/>
                                    <input ref={diplomaInputRef} style={{ display: 'none' }} type='file' multiple className='uploader' onChange={handleDiplomaUpload} accept="application/pdf, image/gif, image/jpeg, image/png, image/heic"/>
                                </div>
                            </div>
                        : <></>
                    }
                </div>
            </div>
            
            <div className='button-save' onClick={saveChanges}>{tabIndex === 1 || tabIndex === 2 ? "Продолжить" : "Сохранить"}</div>
        </div>
    )
}

export default TeacherEditPage