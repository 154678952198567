import React, { useEffect, useRef, useState } from 'react';
import PostService from '../API/PostService';
import axios from 'axios';

const VideoPlayer = ({ videoPath }) => {

    const videoRef = useRef(null);
    const [url, setUrl] = useState(videoPath)

    useEffect(()=>{
        setUrl(videoPath)
        console.log('videoplayer useeffect:', videoPath);
        
        videoRef.current.load()
    },[])

    return (
        <div className='video-container'>
            <video ref={videoRef} controls width="600">
                <source src={`https://global.s3.cloud.ru/files-repetitme/public-folder/${videoPath}`} />
            </video>
        </div>
    );
};

export default VideoPlayer;
