import '../App.css'
import st1icon from '../icons/1.Как пользоваться сервисом — копия.jpg'
import st2icon from '../icons/2. Преимущества для ученика.jpg'
import st3icon from '../icons/3. Способы подобрать репетитора.jpg'
import st4icon from '../icons/4.Обратная связь от ученика.jpg'
import st5icon from '../icons/5. Как проводить онлайн-занятия — копия.jpg'
import st6icon from '../icons/6.Как оставить отзыв о репетиторе_ — копия.jpg'
import st7icon from '../icons/7.FAQ ученики.jpg'

import rp2icon from '../icons/2. Преимущества сервиса (реп).jpg'
import rp3icon from '../icons/3. Анкета репетитора.jpg'
import rp4icon from '../icons/4. Как формируется рейтинг.jpg'
import rp5icon from '../icons/5. Как формируется комиссия.jpg'
import rp6icon from '../icons/6. Уведомления (реп).jpg'
import rp7icon from '../icons/7.Как оставить отзыв о репетиторе_.jpg'
import rp8icon from '../icons/8.Как проводить онлайн-занятия.jpg'
import rp9icon from '../icons/9. FAQ репетиторы.jpg'
import { useNavigate } from 'react-router-dom'

function StoriesCarousel({isMain}) {
    return (
        <div className='stories-carousel-container'>
            {
            (isMain === true) ?
            <div className='stories-carousel'>
                <StoryCard text={'Как пользоваться сервисом'} image={st1icon} link={`https://teletype.in/@repetitme/about_us`} isMain={isMain}/>
                <StoryCard text={'Преимущества для ученика'}  image={st2icon} link={`https://teletype.in/@repetitme/adv_for_students`} isMain={isMain}/>
                <StoryCard text={'Способы подобрать репетитора'} image={st3icon} link={`https://teletype.in/@repetitme/ways_to_find_tutors`} isMain={isMain}/>
                <StoryCard text={'Обратная связь от ученика'} image={st4icon} link={`https://teletype.in/@repetitme/feedback_students`} isMain={isMain}/>
                <StoryCard text={'Как проводить онлайн-занятия'} image={st5icon} link={`https://teletype.in/@repetitme/online_lessons`} isMain={isMain}/>
                <StoryCard text={'Как оставить отзыв о репетиторе'} image={st6icon} link={`https://teletype.in/@repetitme/review`} isMain={isMain}/>
                <StoryCard text={'FAQ ученики'} image={st7icon} link={`https://teletype.in/@repetitme/faq_students`} isMain={isMain}/>
            </div>
            :
            <div className='stories-carousel'>
                <StoryCard text={'Как пользоваться сервисом'} image={st1icon} link={`https://teletype.in/@repetitme/about_us`} isMain={isMain}/>
                <StoryCard text={'Преимущества сервиса для репетиторов '}  image={rp2icon} link={`https://teletype.in/@repetitme/adv_for_tutors`} isMain={isMain}/>
                <StoryCard text={'Анкета репетитора '} image={rp3icon} link={`https://teletype.in/@repetitme/from_for_tutors`} isMain={isMain}/>
                <StoryCard text={'Как формируется комиссия за заявки? '} image={rp4icon} link={`https://teletype.in/@repetitme/about_fee`} isMain={isMain}/>
                <StoryCard text={'Как формируется рейтинг?'} image={rp5icon} link={`https://teletype.in/@repetitme/rating_of_tutor`} isMain={isMain}/>
                <StoryCard text={'Уведомления для репетитора'} image={rp6icon} link={`https://teletype.in/@repetitme/feedback_tutors`} isMain={isMain}/>
                <StoryCard text={'Как оставить отзыв о репетиторе'} image={rp7icon} link={`https://teletype.in/@repetitme/review`} isMain={isMain}/>
                <StoryCard text={'Как проводить онлайн-занятия?'} image={rp8icon} link={`https://teletype.in/@repetitme/online_lessons`} isMain={isMain}/>
                <StoryCard text={'FAQ - вопросы репетиторов'} image={rp9icon} link={`https://teletype.in/@repetitme/faq_tutors`} isMain={isMain}/>
            </div>
        }
        </div>
    )
}

function StoryCard( { text, image, link, isMain } ) {

    const navigate = useNavigate()
    const goStory = () => navigate('/story', {state: {link: link, isMain: isMain}})

    const handleClick = () =>{
        console.log('click');
        goStory()
      }

    return (
        <div className='story-card' onClick={handleClick}>
            <img src={image} alt='new'></img>
            {<div>{text}</div>}
        </div>
    )
}

export default StoriesCarousel