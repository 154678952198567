import '../App.css'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PostService from '../API/PostService'
import TeacherSciencesList from '../components/TeacherSciencesList'
import TextareaAutosize from 'react-textarea-autosize'

const tgWebApp = window.Telegram.WebApp;

function TeacherUpdatePage() {
    const { id } = useParams()

    const [currentTeacher, setCurrentTeacher] = useState()
    const [currentUser, setCurrentUser] = useState()
    const [scienceList, setScienceList] = useState([])
    const [targetList, setTargetList] = useState([])
    const [ageList, setAgeList] = useState([])
    const [statusList, setStatusList] = useState([])
    const [scienceAssociations, setScienceAssociations] = useState([])
    const [teacherName, setTeacherName] = useState('')
    const [teacherDescription, setTeacherDescription] = useState('')
    const [teacherPrice, setTeacherPrice] = useState(0)
    const [teacherExperience, setTeacherExperience] = useState(0)
    const [isMyPage, setIsMyPage] = useState(false)

    const navigate = useNavigate()

    const goBack = () => navigate(isMyPage ? '/teacher' :'/', {replace: isMyPage})

    const BackButton = tgWebApp.BackButton
    BackButton.show()
    BackButton.onClick(goBack)

    const handleScienceCardUpdate = ( science, target, age, teacherStatus ) => {
        const association = {
            "science_id": science,
            "age_ids": age,
            "lesson_target_ids": target,
            "teacher_status": teacherStatus
        }
        if ( scienceAssociations === undefined )
            setScienceAssociations(association)
        else
        {
            let tempAssoc = []
            tempAssoc.push(association)
            setScienceAssociations(tempAssoc)
        }
    }

    const handleNameChange = ( event ) => {
        setTeacherName(event.target.value)
    }

    const handleDescriptionChange = ( event ) => {
        setTeacherDescription(event.target.value)
    }

    const handlePriceChange = ( event ) => {
        setTeacherPrice(event.target.value)
    }

    const handleExperienceChange = ( event ) => {
        setTeacherExperience(event.target.value)
    }

    const saveTeacher = async () => {
        if ( scienceAssociations !== undefined && scienceAssociations.length !== 0 &&
        teacherName != undefined && teacherName.length !== 0 && teacherExperience !== 0 &&
        teacherDescription != undefined && teacherDescription.length !== 0 && teacherPrice !== 0 )
        {
            const teacherObject = {
                "name":teacherName,
                "science_associations": scienceAssociations,
                "experience":teacherExperience,
                "about_me":teacherDescription,
                "price":teacherPrice,
                "is_visible":true
            }
            const response = (currentTeacher === null ) ? await PostService.becomeTeacher(teacherObject) : await PostService.updateTeacher(teacherObject)
        }    
    }

    const createRequest = async () => {
        const response = await PostService.getCurrentStudent()
        console.log(response)
        if ( response.status !== 200 )
        {
            console.log('need to create student');
            return
        }
        console.log(currentTeacher);
        console.log(scienceAssociations);
        const requestObj = {
            "additional_order_text": "Тестовый текст",
            "teacher_id": currentTeacher.id,
            "science_id": scienceAssociations[0].science_id,
            "lessons_target_id": scienceAssociations[0].lesson_target_ids[0],
            "age_category_id": scienceAssociations[0].age_ids[0]
          }
          console.log(requestObj);
          const request = await PostService.makeOrder(requestObj)
          console.log(request);
    }

    useEffect( () => {
        const initUser = async () => {
            const response = await PostService.getCurrentUser()

            if ( response.status !==200 )
            {}
            setCurrentUser(response.data)
        }

        initUser()
    },[])

    useEffect(() => {
        const initTeacher = async () => {
            if ( currentTeacher !== undefined )
                return
            const isMy = id === undefined
            setIsMyPage(isMy)
			let response = isMy ? await PostService.getTeacherMe() : await PostService.getTeacher(id)
			if ( response.status !==200 )
            {
                if ( response.code === "ERR_BAD_REQUEST" )
                {
                    setCurrentTeacher(null)
                    console.log('404');
                }
            }
            if ( scienceList.length === 0 || targetList.length === 0 
                || statusList.length === 0 || ageList.length === 0 )
                await getSciences()
            
            
            setCurrentTeacher(response.data)
            setTeacherName(response.data?.name)
            setTeacherPrice(response.data?.price)
            setTeacherExperience(response.data?.experience)
            setTeacherDescription(response.data?.about_me)
        }

        const getSciences = async () => {
            if ( scienceList.length !== 0 )
                return
            let response = await PostService.getSciences()
            if ( response.status !== 200 )
            {
                console.log(response)
            }
            setScienceList(response.data)

            response = await PostService.getAges()
            if ( response.status !== 200 )
            {
                console.log(response)
            }
            setAgeList(response.data)

            response = await PostService.getTargets()
            if ( response.status !== 200 )
            {
                console.log(response)
            }
            setTargetList(response.data)

            response = await PostService.getTeacherStatuses()
            if ( response.status !== 200 )
            {
                console.log(response)
            }
            setStatusList(response.data)
        }

        initTeacher()
    },[currentTeacher, teacherName, teacherPrice, teacherExperience])

    return (
        <div className='teacher-page'>
            <div className='teacher-bg'></div>
            <div className='avatar-container'>
                    <img className="avatar-image" src="https://sun9-72.userapi.com/impg/oErJGs22nV9hEBFVaJb265b4mJWkSuQEKSfzQw/8cALmH0jVVE.jpg?size=516x304&quality=96&sign=a857802168baf4230f1521aa91b1745b&type=album" alt='new' />
                </div>
            <div className='teacher-header'>
                
                <input disabled={!isMyPage} className='teacher-name' value={teacherName} placeholder='Имя' onChange={handleNameChange} />
                {
                    isMyPage ?
                    <div className='teacher-nickname'>@{currentUser?.telegram_name}</div>
                    : <></>
                }
                
                <div className='feedback-container'>
                    {`Рейтинг: ${currentTeacher?.rating}`}
                </div>
            </div>
            <div className='teacher-section'>
                <h1>Обо мне</h1>
                <TextareaAutosize disabled={!isMyPage} onChange={handleDescriptionChange} value={teacherDescription} className='teacher-description' placeholder='Описание'/>
                <div className='teacher-numbers'>
                    <input disabled={!isMyPage} onChange={handlePriceChange} value={teacherPrice} className='price-input' type='number' placeholder='Стоимость/час'/>
                    <input disabled={!isMyPage} onChange={handleExperienceChange} value={teacherExperience} className='experience-input' type='number' placeholder='Опыт'/>
                </div>
            </div>
            <div className='teacher-section'>
                <h1>Дисциплины</h1>
                <TeacherSciencesList sciencesInit={currentTeacher?.science_associations} scienceList={scienceList}
                targetList={targetList} ageList={ageList} statusList={statusList} onCardReady={handleScienceCardUpdate} isMyPage={isMyPage} />
            </div>
            <div className='teacher-section'>
                <h1>Видеопрезентация</h1>
            </div>
            <div className='teacher-section'>
                <h1>Дипломы</h1>
            </div>
            <button onClick={isMyPage ? saveTeacher : createRequest } className='button-save'>{isMyPage ? 'Сохранить изменения' : 'Записаться к преподавателю'}</button>
        </div>
    )
}

export default TeacherUpdatePage