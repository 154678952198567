import { Route, Navigate, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'

import MainPage from './pages/MainPage'
import TeacherUpdatePage from './pages/TeacherUpdatePage'
import TeacherMainPage from './pages/TeacherMainPage'
import PickTeacherMenuPage from './pages/PickTeacherMenuPage'
import FilterPage from './pages/FilterPage'
import TeacherSearchPage from './pages/TeacherSearchPage'
import StudentOverviewHeader from './components/StudentOverviewHeader'
import StudentOverviewPage from './pages/StudentOverviewPage'
import StudentUpdatePage from './pages/StudentUpdatePage'
import TeacherOverviewPage from './pages/TeacherOverviewPage'
import TeacherEditPage from './pages/TeacherEditPage'
import StudentResponsesPage from './pages/StudentResponsesPage'
import TeacherResponsesPage from './pages/TeacherResponsesPage'
import StudentDebtPage from './pages/StudentDebtPage'
import BonusesPage from './pages/BonusesPage'
import TeacherReviewsPage from './pages/TeacherReviewsPage'
import LessonReportPage from './pages/LessonReportPage'
import StoryPage from './pages/StoryPage'
import AddPage from './pages/AddPage'
import InitialReportPage from './pages/InitialReportPage'

const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/">
        <Route index element={<MainPage />} />
        <Route path='/teacher/me' element={<TeacherOverviewPage />} />
        <Route path='/teacher/:id' element={<TeacherOverviewPage />} />
        <Route path='/teacher' element={<TeacherMainPage />} />
        <Route path='/choice' element={<PickTeacherMenuPage />} />
        <Route path='/filter' element={<FilterPage />} />
        <Route path='/teachers' element={<TeacherSearchPage />} />
        <Route path='/student/me' element={<StudentOverviewPage />} />
        <Route path='/student/update' element={<StudentUpdatePage />} />
        <Route path='/teacher/edit' element={<TeacherEditPage />} />
        <Route path='/student/requests' element={<StudentResponsesPage />} />
        <Route path='/teacher/requests' element={<TeacherResponsesPage />} />
        <Route path='/student/:id' element={<StudentDebtPage />} />
        <Route path='/student/report' element={<LessonReportPage />} />
        <Route path='/bonuses' element={<BonusesPage />} />
        <Route path='/teacher-reviews/:id' element={<TeacherReviewsPage />} />
        <Route path='/teacher-reviews/me' element={<TeacherReviewsPage />} />
        <Route path='/story' element={<StoryPage />}/>
        <Route path='/student/initial/:id' element={<InitialReportPage />} />
    </Route>
))

export default router