import { useLocation, useNavigate } from 'react-router-dom';
import '../App.css'
import TextareaAutosize from 'react-textarea-autosize'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { useEffect, useState } from 'react';
import PostService from '../API/PostService';
import LessonList from '../components/LessonList'
import TimePicker from '../components/TimePicker';

const tgWebApp = window.Telegram.WebApp;

function LessonReportPage() {

    const location = useLocation()
    const cardData = location.state 

    const navigate = useNavigate()
    const goBack = (data) => navigate(`/student/${cardData.id}`, {state: data})

    const BackButton = tgWebApp.BackButton
    BackButton.show()
    BackButton.onClick(goBack)

    const [date, setDate] = useState(Date())
    const [time, setTime] = useState('00:00')
    const [price, setPrice] = useState(0)
    const [displayCalendar, setDisplayCalendar] = useState(false)
    const [displayDate, setDisplayDate] = useState(false)
    const [displayPicker, setDisplayPicker] = useState(false)
    
    const handleDate = ( value ) => {
        console.log(value);
        setDate(value)
        console.log(value.toLocaleDateString('ru-RU', { month: 'long', year: 'numeric' }));
        
        setDisplayDate(true)
        setDisplayCalendar(false)
    }

    const handleTime = ( hour, minute ) => {
        console.log( hour, minute, `${hour < 10 ? '0' : ''}${hour.toString()}:${minute < 10 ? '0' : ''}${minute}`,  minute < 10, displayDate  )
        setTime(`${hour < 10 ? '0' : ''}${hour.toString()}:${minute < 10 ? '0' : ''}${minute}`)
    }

    const handlePriceChange = ( event ) => {
        console.log( event.target.value )
        setPrice(Number(event.target.value))
    }

    const handleCalDisplayClick = () => {
        setDisplayCalendar(!displayCalendar)
    }

    const handleTimeDisplayClick = () => {
        setDisplayPicker(!displayPicker)
    }

    const handleLesson = async () => {
        console.log(Date(date), time, typeof(Date(date)));
        let dateTime = new Date(date)
        const hours = Number(time.slice(0,2))
        const minutes = Number(time.slice(3,5))

        dateTime.setHours(hours,minutes,0)
        console.log(dateTime.toJSON(), price);
        const lessonObj = {
            "student_id": cardData.student_id,
            "date_of_lesson": dateTime.toJSON(),
            "price": price,
            "science_id": cardData.science_id
        }
        console.log('lessonobj',lessonObj);
        let data = {...cardData}
        console.log(data);

        const response = await PostService.createLesson(lessonObj)
        if ( response.status !== 200 )
        {
            console.log(response);
        }
        console.log('response',response.data);
        goBack(data)
    }   

    useEffect(()=>{
    },[])

    return (
        <div className='lesson-report-page'>
            <div className='header'>Создание урока</div>
            <div className='report-title'>Итоговая стоимость занятия</div>
            <div className='price-container'>
                <input className='price-input' type='number' placeholder='Стоимость' value={''+price} onChange={handlePriceChange} />
                <div>рублей</div>
            </div>
        
            <div className='report-title'>Дата</div>
            <div className={displayDate ? 'date-container active' : 'date-container'} onClick={handleCalDisplayClick}>{displayDate ? date.toLocaleDateString('ru-RU', { day: 'numeric', month: 'long', year: 'numeric' }) :'Выберите дату'}</div>
            {
                    displayCalendar ?
                    <Calendar onChange={handleDate} locale='ru-RU' value={date} 
                    minDetail='year' prev2Label={null} next2Label={null}
                    formatMonthYear={(locale, date) => {
                        const validDate = new Date(date);
                        console.log('date',typeof(validDate.toLocaleDateString(locale, { month: 'long', year: 'numeric' })));
                        let month = validDate.toLocaleDateString(locale, { month: 'long' });
                        const year = validDate.getFullYear();
                        month = month.charAt(0).toUpperCase() + month.slice(1);
                        return (
                            <div>
                            {month} <span style={{ color: '#6757F1'}}>{year}</span>
                            </div>
                        );
                    }}
                    formatMonth={(locale, date) => {
                        const validDate = new Date(date)
                        let month = validDate.toLocaleDateString(locale, { month: 'short' });
                        month = month.charAt(0).toUpperCase() + month.slice(1, month.length-1);
                        return month
                    }}
                    />
                    : <></>
            }
            
            <div className='time-container' onClick={handleTimeDisplayClick}>Время занятия</div>
            {
                displayPicker ?
                <TimePicker className='timepicker' handleTimeChange={handleTime}/>
                : <></>
            }
            <div className='button-send' onClick={handleLesson}>Создать урок</div>
        </div>
    )
}

export default LessonReportPage