import { useEffect, useState } from 'react'
import '../App.css'
import PostService from '../API/PostService';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import TimePicker from '../components/TimePicker';
import { useNavigate } from 'react-router-dom';


const tgWebApp = window.Telegram.WebApp;

function InitialReportPage() {

    const navigate = useNavigate()
    const goBack = (data) => navigate(`/teacher/`, {state: data})

    const BackButton = tgWebApp.BackButton
    BackButton.show()
    BackButton.onClick(goBack)

    const [tabIndex, setTabIndex] = useState(1)

    const [selectedOption, setSelectedOption] = useState('option1');

    const [date, setDate] = useState(Date())
    const [time, setTime] = useState('00:00')
    const [price, setPrice] = useState(0)
    const [displayCalendar, setDisplayCalendar] = useState(false)
    const [displayDate, setDisplayDate] = useState(false)
    const [displayPicker, setDisplayPicker] = useState(false)

    console.log(JSON.parse(localStorage.getItem('pickedStudent')));
    
    const handleRadio = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleButton = async () => {
        if ( tabIndex === 1 && selectedOption === 'option1')
        {
            setTabIndex(2)
            return
        }
        if ( tabIndex === 2 && selectedOption === 'option1')
            {
                handleLesson()
                return
            }
    }

    const handleDate = ( value ) => {
        console.log(value);
        setDate(value)
        console.log(value.toLocaleDateString('ru-RU', { month: 'long', year: 'numeric' }));
        
        setDisplayDate(true)
        setDisplayCalendar(false)
    }

    const handleTime = ( hour, minute ) => {
        console.log( hour, minute, `${hour < 10 ? '0' : ''}${hour.toString()}:${minute < 10 ? '0' : ''}${minute}`,  minute < 10, displayDate  )
        setTime(`${hour < 10 ? '0' : ''}${hour.toString()}:${minute < 10 ? '0' : ''}${minute}`)
    }

    const handlePriceChange = ( event ) => {
        console.log( event.target.value )
        setPrice(Number(event.target.value))
    }

    const handleCalDisplayClick = () => {
        setDisplayCalendar(!displayCalendar)
    }

    const handleTimeDisplayClick = () => {
        setDisplayPicker(!displayPicker)
    }

    const handleLesson = async () => {
        console.log(Date(date), time, typeof(Date(date)));
        let dateTime = new Date(date)
        const hours = Number(time.slice(0,2))
        const minutes = Number(time.slice(3,5))
        console.log(hours, minutes);
        

        dateTime.setHours(hours,minutes,0)
        console.log(dateTime);
        
        console.log(dateTime.toJSON(), price);
        const cardData = JSON.parse(localStorage.getItem('pickedStudent'))
        console.log(cardData);
        
        const lessonObj = {
            "student_id": cardData.student_id,
            "date_of_lesson": dateTime.toJSON(),
            "price": price,
            "science_id": cardData.science_id
        }
        console.log('lessonobj',lessonObj);
        
        

        const response = await PostService.createLesson(lessonObj)
        if ( response.status !== 200 )
        {
            console.log(response);
        }
        console.log('response',response.data);
        goBack(cardData)
    }

    return(
        <div className='initial-report-page'>
            <div className='title'>{tabIndex === 1 ? 'Договорились о первом занятии?' : 'Создание урока'}</div>
            <div className={tabIndex === 1 ? 'content active-content': 'content' } >
                <label className='radio-button-container'>
                    <input className='radio-button' type="radio" value="option1" checked={selectedOption === 'option1'} onChange={handleRadio} name="options" />
                    Да
                </label>
                <label className='radio-button-container'>
                    <input className='radio-button' type="radio" value="option2" checked={selectedOption === 'option2'} onChange={handleRadio} name="options"/>
                    Нет
                </label>
            </div>
            <div className={tabIndex === 2 ? 'content active-content': 'content' } >
                <div className='report-title'>Итоговая стоимость занятия</div>
                <div className='price-container'>
                    <input className='price-input' type='number' placeholder='Стоимость' value={''+price} onChange={handlePriceChange} />
                    <div>рублей</div>
                </div>
            
                <div className='report-title'>Дата</div>
                <div className={displayDate ? 'date-container active' : 'date-container'} onClick={handleCalDisplayClick}>{displayDate ? date.toLocaleDateString('ru-RU', { day: 'numeric', month: 'long', year: 'numeric' }) :'Выберите дату'}</div>
                {
                    displayCalendar ?
                    <Calendar onChange={handleDate} locale='ru-RU' value={date} 
                    minDetail='year' prev2Label={null} next2Label={null}
                    formatMonthYear={(locale, date) => {
                        const validDate = new Date(date);
                        console.log('date',typeof(validDate.toLocaleDateString(locale, { month: 'long', year: 'numeric' })));
                        let month = validDate.toLocaleDateString(locale, { month: 'long' });
                        const year = validDate.getFullYear();
                        month = month.charAt(0).toUpperCase() + month.slice(1);
                        return (
                            <div>
                            {month} <span style={{ color: '#6757F1'}}>{year}</span>
                            </div>
                        );
                    }}
                    formatMonth={(locale, date) => {
                        const validDate = new Date(date)
                        let month = validDate.toLocaleDateString(locale, { month: 'short' });
                        month = month.charAt(0).toUpperCase() + month.slice(1, month.length-1);
                        return month
                    }}
                    />
                    : <></>
                }
                
                <div className='report-title'>Время занятия</div>
                <div className='time-container' onClick={handleTimeDisplayClick}>{time}</div>
                {
                    displayPicker ?
                    <TimePicker handleTimeChange={handleTime}/>
                    : <></>
                }
                
            </div>
            <div className='button' onClick={handleButton}>{tabIndex === 1 ? 'Далее' : 'Создать'}</div>
        </div>
    )
}

export default InitialReportPage