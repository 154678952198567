import '../App.css'
import Select from 'react-select'
import TextareaAutosize from 'react-textarea-autosize'
import { useEffect, useState, useRef, useContext } from 'react'
import { AuthContext } from '../hoc/AuthProvider'
import { useNavigate } from 'react-router-dom'
const tgWebApp = window.Telegram.WebApp;

function FilterPage() {

    const { setGFilter } = useContext(AuthContext)

    const targetsRef = useRef()
    const agesRef = useRef()
    const statusesRef = useRef()

    const [sciences, setSciences] = useState([])

    const [scTargets, setScTargets] = useState([])
    const [scAges, setScAges] = useState([])
    const [scStatuses, setScStatuses] = useState([])

    const [scienceValue, setScienceValue] = useState()
    const [targetValues, setTargetValues] = useState([])
    const [ageValues, setAgeValues] = useState([])
    const [statusValues, setStatusValues] = useState([])

    const [price, setPrice] = useState(0)
    const [experience, setExperience] = useState(0)
    const [description, setDescription] = useState('')

    const navigate = useNavigate()

    const goTeacherPage = () => navigate(`/teachers`)

    const goBack = () => navigate('/', {replace: true})

    const BackButton = tgWebApp.BackButton
    BackButton.show()
    BackButton.onClick(goBack)

    const handleScienceChange = ( selectedOption ) => {
        let value = selectedOption !== null ? selectedOption.value : null
        setScienceValue( value )
        targetsRef.current.clearValue()
        agesRef.current.clearValue()
        statusesRef.current.clearValue()
    }

    const handleAgesChange = ( selectedOptions ) => {
        let values = []
        selectedOptions.forEach( option => values.push(option.value))
        setAgeValues(values)
    }

    const handlePriceChange = ( event ) => {
        console.log(Math.round(event.target.value));
        setPrice(Math.round(event.target.value))
    }

    const handleExperienceChange = ( event ) => {
        console.log(Math.round(event.target.value));
        setExperience(Math.round(event.target.value))
    }

    const handleTargetsChange = ( selectedOptions ) => {
        let values = []
        selectedOptions.forEach( option => values.push(option.value))
        setTargetValues(values)
    }

    const handleStatusesChange = ( selectedOptions ) => {
        let values = []
        selectedOptions.forEach( option => values.push(option.value))
        setStatusValues(values)
    }

    const handleDescriptionChange = ( event ) => {
        setDescription(event.target.value)
    }

    const handleAccept = () => {
        let filter = {}
        if ( scienceValue === undefined )
            return
        filter.sciences_id = [scienceValue]
        if (statusValues.length)
            filter.teacher_statuses = statusValues
        if (targetValues.length)
            filter.lesson_targets = targetValues
        if (ageValues.length)
            filter.ages = ageValues
        filter.price = price
        filter.experience = experience
        filter.description = description
        setGFilter(filter)
        goTeacherPage()
    }

    useEffect( () => {
        const scienceObj = JSON.parse(localStorage.getItem('GSciences')).find( sc => sc.id === scienceValue )

        if (scienceObj === undefined)
            return

        let scTargetList = []
        scienceObj.targets.forEach( trg => {
            const targetObj = JSON.parse(localStorage.getItem('GTargets')).find( t => t.id === trg )
            scTargetList.push({value:targetObj.id, label:targetObj.name})
        })
        setScTargets(scTargetList)

        let scAgeList = []
        scienceObj.ages.forEach( ag => {
            const ageObj = JSON.parse(localStorage.getItem('GAges')).find( a => a.id === ag)
            scAgeList.push({value:ageObj.id, label:ageObj.name})
        })
        setScAges(scAgeList)

        let scStatusList = []
        scienceObj.statuses.forEach( sts => {
            const statusObj = JSON.parse(localStorage.getItem('GStatuses')).find( s => s.id === sts)
            scStatusList.push({value:statusObj.id, label:statusObj.name})
        })
        setScStatuses(scStatusList)

    }, [scienceValue])

    useEffect( () => {
        let tempSciences = []
        if (!JSON.parse(localStorage.getItem('GSciences')).length)
            return
        JSON.parse(localStorage.getItem('GSciences')).forEach( sc => 
            tempSciences.push({value:sc.id, label:sc.name})
        )
        console.log(tempSciences);
        if ( sciences.length === 0)
            setSciences(tempSciences)
        
    }, [])

    return (
        <div className='filter-page'>
            <div className='filter-title'>Фильтры</div>
            <div className='filter-header'>Предмет</div>
            <Select placeholder={'Предмет'} isSearchable={false} inputProps={{readOnly:true}} onChange={handleScienceChange} isClearable
            className='science-name select' options={sciences} /*onFocus={() => setFocus(1)} onBlur={() => setFocus(0)}*/
            menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
            <div className='filter-header'>Возраст</div>
            <Select ref={agesRef} isSearchable={false} placeholder={'Возраст'} inputProps={{readOnly:true}} isMulti  onChange={handleAgesChange}
            isClearable className='science-ages select' options={scAges}
            menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
            <div className='filter-header'>Цель занятий</div>
            <Select ref={targetsRef} isSearchable={false} placeholder={'Направления'} inputProps={{readOnly:true}} isMulti  onChange={handleTargetsChange}
            isClearable className='science-targets select' options={scTargets}
            menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
            <div className='filter-header'>Цена до ₽</div>
            <input className='price-input' type='number' placeholder='До' value={''+price} onChange={handlePriceChange}/>
            <div className='filter-header'>Стаж от</div>
            <input className='price-input' type='number' placeholder='От' value={''+experience} onChange={handleExperienceChange}/>
            <div className='filter-header'>Статус репетитора</div>
            <Select ref={statusesRef} isSearchable={false} placeholder={'Статус преподавателя'} inputProps={{readOnly:true}} isMulti  onChange={handleStatusesChange}
            isClearable className='science-status select' options={scStatuses}
            menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
            {
                true ?
                <div className='filter-header'>Доп. информация</div>
                : <></>    
            }
            
            {
                true ?
                <TextareaAutosize placeholder='Желаемое время занятий, ваш уровень, конкретную цель занятий или другую важную информацию для репетитора' className='textarea-desc' onChange={handleDescriptionChange} value={description} />
                : <></>    
            }
            
            <div className='accept' onClick={handleAccept}>Применить</div>
            <div style={{minHeight:'20px'}}></div>
        </div>
    )
}

export default FilterPage