import '../App.css'
import StarRatings from 'react-star-ratings';

function TeacherReviewsList( { reviews } ) {
    return(
        <div className='reviews-list'>
            { reviews !== undefined &&reviews.length !== 0 ?
                <div>
                    {reviews.map((review, index) => 
                        <ReviewCard cardInit={review}/>
                    )}
    
                </div>
                : <div>No reviews</div>
            }

        </div>
    )
}

export default TeacherReviewsList

function ReviewCard( {cardInit} ) {

    const date = new Date(cardInit.created_at);

    // Define options for toLocaleDateString
    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    };

    // Format the date
    const formattedDate = date.toLocaleDateString('en-GB', options).replaceAll('/','.')

    return (
        <div className='review-card'>
            <div className='info-container'>
                <img className="student-image" src="https://i.ibb.co/f1cgfHG/image.png" alt="new" />
                <div className='creds'>
                    <div className='name'>Ученик</div>
                    <div className='date'>{formattedDate}</div>
                </div>
                <StarRatings rating={cardInit.review_rating}
                    starRatedColor="#f6da43"
                    numberOfStars={5}
                    starDimension='18px'
                    starSpacing='0px'
                />
            </div>
            <div className='info'>{cardInit.review_text}</div>
        </div>
    )
}