import { useNavigate } from 'react-router-dom'
import '../App.css'
import copyIcon from '../icons/copy.svg'
import { useEffect, useState } from 'react';
import PostService from '../API/PostService';
const tgWebApp = window.Telegram.WebApp;

function BonusesPage() {

    const navigate = useNavigate()

    const goBack = () => navigate('/', {replace: true})

    const BackButton = tgWebApp.BackButton
    BackButton.show()
    BackButton.onClick(goBack)

    const [refString, setRefString] = useState('')

    const handleCopyClick = () => {
        navigator.clipboard.writeText(refString)
          .then(() => {
            console.log('Text copied to clipboard');
            // Optionally, you can show a success message or notification here
          })
          .catch((err) => {
            console.error('Failed to copy text: ', err);
            // Optionally, you can show an error message or notification here
          });
      };

    useEffect(() => {
        const getRef = async () => {
            try {
                const response = await PostService.getRefCode()
                console.log(response.data);
                setRefString(response.data)
                
            } catch(error) {
                console.error(error)
            }
        }

        getRef()
    }, [])

    return (
        <div className='bonuses-page'>
            <div className='title'>Бонусы</div>
            <div className='ref-title'>Реферальный код</div>
            <div onClick={handleCopyClick} className='ref-value'>{refString}<img src={copyIcon}/></div>
            <div className='bonus-container'>
                <div className='amount-title'>Количество баллов:</div>
                <div className='amount-value'>0</div>
            </div>
            <div className='desc-title'>Зачем получать баллы?</div>
            <div className='desc-body'>Баллы можно обменять на денежное вознаграждение</div>
            <div className='desc-title'>За что начисляются баллы?</div>
            <div className='desc-body'>Если вы пригласили в приложение друга, и друг прошёл 5 занятий с репетитором, вы получаете баллы.1 занятие друга = 100 баллов</div>
            <div className='desc-title'>Как пригласить друга?</div>
            <div className='desc-body'>Скопируйте реферальную ссылку и отправьте другу. Он должен указать ссылку при регистрации</div>
            <div className='desc-title'>Как обменять баллы на денежное вознаграждение?</div>
            <div className='desc-body'>Когда вы накопите 500 баллов, на этом экране появится кнопка “Вывести баллы”. Нажав на неё, вы перейдёте в чат с нашим менеджером. Он отправит деньги вам на карту</div>
            <div className='button'>Вывести баллы</div>
        </div>
    )
}

export default BonusesPage