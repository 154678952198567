import { useContext, useRef, useEffect, useState } from 'react'
import PostService from '../API/PostService'
import '../App.css'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../hoc/AuthProvider'
import Select from 'react-select'

const ModalStudentResponded = ( {active, setActive, teacher, sciences} ) => {

    const navigate = useNavigate()
    const goStudentResponses = () => navigate(`/student/requests`,{replace: true})

    const targetsRef = useRef()
    const agesRef = useRef()

    const [sciencesFull, setSciencesFull] = useState([])

    const [sciencesLabels, setSciencesLabels] = useState([])
    const [ageLabels, setAgeLabels] = useState([])
    const [trgLabels, setTrgLabels] = useState([])

    const [scienceValue, setScienceValue] = useState({})
    const [targetValue, setTargetValue] = useState({})
    const [ageValue, setAgeValue] = useState({})

    const { GOrders, setGOrders } = useContext(AuthContext)

    const handleScienceChange = ( selectedOption ) => {
        console.log(selectedOption);
        let value = selectedOption !== null ? selectedOption.value : null
        setScienceValue( selectedOption )
        //targetsRef?.current?.clearValue()
        //agesRef?.current?.clearValue()
        const science = sciencesFull.find(sc => sc.science.id === selectedOption.value)
        console.log(science);
        setTrgLabels(science.targetLabels)
        setAgeLabels(science.ageLabels)
        setTargetValue(science.targetLabels[0])
        setAgeValue(science.ageLabels[0])
        
    }

    const handleAgeChange = ( selectedOption ) => {
        console.log('ages',selectedOption);
        setAgeValue( selectedOption )
    }

    const handleTargetChange = ( selectedOption ) => {
        console.log('ages',selectedOption);
        setTargetValue( selectedOption )
    }

    useEffect(()=>{
        const setSciences = () => {
            console.log('setSciences:',sciences);
            console.log('setSciences targets:',sciences);
            
            if (sciences === undefined)
                return
            if (sciences.length === 0)
                return
            const sciencesTemp = [...sciences]

            let scTargetList = []
            let scAgeList = []
            let scList = []
            sciences.forEach( sc => {
                let trgs = []
                let ags = []
                sc.targetArr.forEach( trg => {
                    const targetObj = JSON.parse(localStorage.getItem('GTargets')).find( t => t.id === trg.id )
                    trgs.push({value:targetObj.id, label:targetObj.name})
                })
                sc.ageArr.forEach( a => {
                    const ageObj = JSON.parse(localStorage.getItem('GAges')).find( t => t.id === a.id )
                    ags.push({value:ageObj.id, label:ageObj.name})
                })
                scTargetList.push(trgs)
                scAgeList.push(ags)
                scList.push({value: sc.science.id, label: sc.science.name})
            })
            if (sciencesTemp.length !== scTargetList.length || sciencesTemp.length !== scAgeList.length || sciencesTemp.length !== scList.length)
                return
            

            for (let i = 0; i<sciencesTemp.length; i++)
            {
                sciencesTemp[i].targetLabels = scTargetList[i]
                sciencesTemp[i].ageLabels = scAgeList[i]
            }
            setSciencesFull(sciencesTemp)
            setSciencesLabels(scList)
            setScienceValue(scList[0])
            setTrgLabels(sciencesTemp[0].targetLabels)
            setTargetValue(sciencesTemp[0].targetLabels[0])
            setAgeLabels(sciencesTemp[0].ageLabels)
            setAgeValue(sciencesTemp[0].ageLabels[0])
        }

        setSciences()
    },[sciences])

    const handleOrderClick = async () => {
        const response = await PostService.getCurrentStudent()
        console.log(response)
        if ( response.status !== 200 )
        {
            console.log('need to create student');
            return
        }
        console.log(teacher);
        if (scienceValue.value === undefined || targetValue.value === undefined || ageValue.value === undefined)
            return
        const requestObj = {
            "additional_order_text": "",
            "teacher_id": teacher.id,
            "science_id": scienceValue.value,
            "lessons_target_id": targetValue.value,
            "age_category_id": ageValue.value
        }
        console.log(requestObj);
        const request = await PostService.makeOrder(requestObj)
        console.log(request);
        setActive(false)
        setGOrders([...GOrders, request.data])
        goStudentResponses()
    }

    return (
        <div className={active ? 'modal-container active' : 'modal-container'} onClick={() => setActive(false)}>
            <div className="student-responded-content" onClick={e => e.stopPropagation()}>
                {
                    sciencesFull.length === 1 ?
                    <div>Предмет: {sciencesFull[0].scienceName}</div>
                    :
                    <Select options={sciencesLabels} isSearchable={false} className='science-name select' placeholder={'Предмет'} value={scienceValue} onChange={handleScienceChange}
                    menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                }
                {
                    trgLabels?.length === 1 ?
                    <div>Направление: {trgLabels[0].label}</div>
                    :
                    <Select ref={targetsRef} options={trgLabels} isSearchable={false} className='science-name select' placeholder={'Направления'} value={targetValue} onChange={handleTargetChange}
                    menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                }
                {
                    ageLabels?.length === 1 ?
                    <div>Возраст: {ageLabels[0].label}</div>
                    :
                    <Select ref={agesRef} options={ageLabels} isSearchable={false} className='science-name select' placeholder={'Возраст'} value={ageValue} onChange={handleAgeChange}
                    menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                }
                <div className="accept-button" onClick={handleOrderClick}>Связаться</div>
            </div>
        </div>
    )
}

export default ModalStudentResponded