import React from "react";
import ScienceCard from "./ScienceCard";

function TeacherSciencesList( { sciencesInit, scienceList, targetList, ageList, statusList, onCardReady, isMyPage } ) {

    return (
        <div className={isMyPage ? 'teacher-sciences-list' : 'teacher-sciences-list disabled'}>
            { sciencesInit !== undefined && sciencesInit.length !== 0 ?
                <div>
                    {sciencesInit.map((science, index) => 
                        <ScienceCard scienceInit={science} scienceList={scienceList}  
                        targetList={targetList} ageList={ageList} statusList={statusList} onCardReady={onCardReady}/>
                    )}
                </div>
                : <ScienceCard scienceInit={undefined} scienceList={scienceList}  
                targetList={targetList} ageList={ageList} statusList={statusList} onCardReady={onCardReady}/>
            }
        </div>
    )
}

export default TeacherSciencesList