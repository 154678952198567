import PostService from '../API/PostService';
import '../App.css'

function ResponseCard( { responseInit } ) {

    const handleClick = async ( isAccepted ) => {
        console.log(responseInit.teacher_request_id, responseInit.id);
        const paramObj = {
            id: responseInit.id,
            response_status: isAccepted ? 'accepted' : 'refused'
        }
        const response = await PostService.answerToTeacherRequestResponse(paramObj, responseInit.teacher_request_id)
        if ( response.status !== 200 )
        {
            console.log(response)
        }
        console.log(response);
    }

   return(
    responseInit !== undefined ?
       <div className='response-card'>
           <div className='response-id'>Response id {responseInit.teacher_request_id}</div>
           <div className='response-teacher'>Teacher id {responseInit.teacher_id}</div>
           <div className='response-date'>Date {responseInit.created_at}</div>
           <div className='response-status'>Status {responseInit.response_status}</div>
           {
            responseInit.response_status === 'waiting' ?
                <div>
                    <button onClick={() => handleClick(true)} className='response-approve'>Согласиться</button>
                    <button onClick={() => handleClick(false)} className='response-approve'>Отказаться</button>
                </div>
                
            : <></>
           }
           
       </div>
       : <div>empty lesson</div>
   )
}

export default ResponseCard