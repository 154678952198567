import '../App.css'
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import OrderList from '../components/OrderList';
import PostService from '../API/PostService';
import TeacherPageHeader from '../components/TeacherPageHeader';
import LessonList from '../components/LessonList';
import ModalQuiz from '../components/ModalQuiz';
import PaymentForm from '../components/PaymentForm';
import StoriesCarousel from '../components/StoriesCarousel';
import { AuthContext } from '../hoc/AuthProvider';
import StudentList from '../components/StudentList';

const tgWebApp = window.Telegram.WebApp;

function TeacherMainPage() {

    const { setGOrders } = useContext(AuthContext)

    localStorage.setItem('entry',1)
    console.log('local storage', localStorage.getItem('entry'));

    const [currentTeacher, setCurrentTeacher] = useState()
    const [avatarSrc, setAvatarSrc] = useState(null)

    const [quizes, setQuizes] = useState([])
    const [modalActiveArr, setModalActiveArr] = useState([])

    const [myStudentList, setMyStudentList] = useState([])
    const [pendingRequestsList, setPendingRequestsList] = useState([])

    const [totalDebt, setTotalDebt] = useState(0)
    const [debtAmount, setDebtAmount] = useState(0)
    const [hasLessons, setHasLessons] = useState(false)


    //const [lessonsDebtArr, setLessonsDebtArr] = useState([])

    //const [token, setToken] = useState('')

    const navigate = useNavigate()

    const goBack = () => navigate('/', {replace: true})
    const goRequests = () => navigate('requests', {replace: true})

    const BackButton = tgWebApp.BackButton
    BackButton.show()
    BackButton.onClick(goBack)

    /*const makePayment = async () => {
        console.log(lessonsDebtArr);
        const lesson = lessonsDebtArr[0]
        const response = await PostService.makePaymentByLessonId(lesson.id)
        console.log(response.data.confirmation.confirmation_token);
        setToken(response.data.confirmation.confirmation_token)
    }*/

    const onQuizSent = async ( quizReport, index ) => {
        let activeArr = modalActiveArr.slice()
        activeArr[index] = false
        activeArr = activeArr.slice(0,-1)
        const response = await PostService.responseTeacherQuiz(quizReport)
        if ( response.status !==200 )
        {
        }
        setModalActiveArr(activeArr)
    }

    const onResponseClick = async ( isAccepted, orderId ) => {
        const statusWord = isAccepted ? 'approved' : 'canceled'
        const paramObj = {
            "id": orderId,
            "order_status": statusWord
        }
    }

    const onRequestResponseClick = async ( isAccepted, requestId ) => {
        if ( !isAccepted )
            return

        const response = await PostService.createResponseToTeacherRequest(requestId)
    }

    useEffect( () => {

        const getTeacher = async () => {
            if ( currentTeacher !== undefined )
                return
            let response = await PostService.getTeacherMe()
            if ( response.status !== 200 )
            {
                
            }
            setCurrentTeacher(response.data)

            let sciences = []
            response = await PostService.getSciences()
            if (response.status !== 200) {
                
            }
            sciences = response.data

            let ages = []
            response = await PostService.getAges()
            if (response.status !== 200) {
                
            }
            ages = response.data

            let targets = []
            response = await PostService.getTargets()
            if (response.status !== 200) {
                
            }
            targets = response.data

            let statuses = []
            response = await PostService.getTeacherStatuses()
            if (response.status !== 200) {
                
            }
            statuses = response.data
            localStorage.setItem('GSciences', JSON.stringify(sciences))
            localStorage.setItem('GStatuses', JSON.stringify(statuses))            
            localStorage.setItem('GTargets', JSON.stringify(targets))
            localStorage.setItem('GAges', JSON.stringify(ages))     

            if ( response.data.teacher_avatar !== undefined )
            {
                response = await PostService.getTeacherAvatar(response.data?.id)
                if ( response.status !==200 )
                {
                    
                }
                else
                {
                    const base64 = btoa(
                        new Uint8Array(response.data).reduce(
                            (data, byte) => data + String.fromCharCode(byte),
                            ''
                        )
                    )
                    setAvatarSrc(`data:image/png;base64,${base64}`)
                }
            }
        }

        getTeacher()
    },[])

    useEffect( () => {

        const getOrderStudents = async ( orders ) => {
            const processedStudents = await Promise.all(orders.map( async (order) => {
                try 
                {
                    const response = await PostService.getOrderStudent(order.id)
                    return response.data
                }
                catch ( error )
                {
                    console.error('getOrderStudents error',order, error)
                    return undefined
                }
                
            }))

            return processedStudents
        }

        const getRequestStudents = async ( requests ) => {
            const processedStudents = await Promise.all(requests.map( async (request) => {
                try 
                {
                    const response = await PostService.getRequestStudentContact(request.id)
                    return response.data
                }
                catch ( error )
                {
                    console.error('getRequestStudents error',request, error)
                    return undefined
                }
                
            }))

            return processedStudents
        }

        const processOrder = ( order, price, sciences, ages, targets ) => {
            const scienceObj = sciences.find( sc => sc.id === order.science_id )
            console.log('processOrder',order, sciences);
    
            if ( scienceObj === undefined )
                return
    
            const age = ages.find( a => a.id === order.age_category_id ).name
            const target = targets.find( t => t.id === order.lessons_target_id ).name
    
            const date = new Date(order.created_at);
            const options = {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                weekday: 'long',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                timeZoneName: 'short'
              };
            const formatter = new Intl.DateTimeFormat('ru-RU', options);
            const formattedDate = formatter.format(date);
            
            return (
                {
                    'science':scienceObj.name,
                    'age': age,
                    'target': target, 
                    'desc': order.additional_order_text,
                    'date': formattedDate,
                    'price': price,
                    'science_id': order.science_id,
                    'id':order.id,
                    'student_id': order.student_id
                }
            )
        }
    
        const processRequest = ( request, sciences, ages, targets ) => {
            const scienceObj = sciences.find( sc => sc.id === request.science_id )
    
            if ( scienceObj === undefined )
                return
    
            const age = ages.find( a => a.id === request.age_category ).name
    
            let scienceTargets = ''
            request.lesson_targets.forEach( (targetId) =>
            {
                const target = targets.find( trg => trg.id === targetId)
                scienceTargets += target.name + '; '
            })
            scienceTargets = scienceTargets.slice(0, scienceTargets.length-2)
    
            return (
                {
                    'science': scienceObj.name, 
                    'age': age,
                    'target': scienceTargets,
                    'desc': request.request_description,
                    'price': request.max_price,
                    'id': request.id,
                    'student_id': request.student_id                
                }
            )
        }

        const getOrders = async () => {

            const sciences = JSON.parse(localStorage.getItem('GSciences'))
            const ages = JSON.parse(localStorage.getItem('GAges'))
            const targets = JSON.parse(localStorage.getItem('GTargets'))

            let response = await PostService.getTeacherMe()

            if ( response.status !== 200)
            {}
            else
            {
            }
            const teacher = response.data

            response = await PostService.getTeacherOrders()
            if ( response.status !== 200)
            {
                console.log(response);
            }
            else
            {
            }

            const studentsIds = response.data

            response = await PostService.getSuitableRequests()
            if ( response.status !== 200)
            {
                console.log(response);
            }
            else
            {
            }

            const suitableRequests = response.data

            let unansweredOrders = studentsIds?.filter( (obj) => obj.order_status === 'waiting' )
            let answeredOrders = studentsIds?.filter( (obj) => obj.order_status === 'approved' )

            response = await PostService.getAcceptedRequests()
            if ( response.status !== 200)
            {
                console.log(response);
            }
            else
            {
            }

            let acceptedRequests = response.data
            console.log('.........................................................');

            let processedRequestStudents = await getRequestStudents(acceptedRequests)

            let processedAcceptedRequests = []
            
            acceptedRequests.forEach( (request) => {
                processedAcceptedRequests.push(processRequest(request, sciences, ages, targets))
            })
            
            if ( processedRequestStudents.length !== processedAcceptedRequests.length )
            {
                console.log('length assert')
                return
            }

            console.log('processedRequestStudents',processedRequestStudents);
            for ( let s = 0; s< processedRequestStudents.length; s++)
            {
                processedAcceptedRequests[s].name = processedRequestStudents[s].name
                processedAcceptedRequests[s].student_id = processedRequestStudents[s].student_id
            }
            console.log('processedAcceptedRequests',processedAcceptedRequests);

            let processedStudents = await getOrderStudents(answeredOrders)

            let processedAnswered = []
            console.log('answeredOrders',answeredOrders);
            answeredOrders.forEach( (order) => {
                processedAnswered.push(processOrder(order, teacher.price,sciences, ages, targets))
            })

            if ( processedStudents.length !== processedAnswered.length )
            {
                console.log('length assert');
                return
            }
            
            console.log('processedAnswered',processedAnswered);
            for ( let s = 0; s< processedStudents.length; s++)
            {
                processedAnswered[s].name = processedStudents[s].name
                processedAnswered[s].student_id = processedStudents[s].student_id
            }
            console.log('processedAnswered',processedAnswered);
            console.log('///////////////////////////////////////////////////////////////');

            let studentList = processedAnswered.concat(processedAcceptedRequests)
            response = await PostService.getLessonsTeacher()
            if ( response.status !== 200)
            {
                console.log(response);
            }
            else
            {
            }
            const lessons = response.data
            response = await PostService.getTeacherDebt()
            if ( response.status !== 200)
            {
                console.log(response);
            }
            else
            {
            }
            const debts = response.data
            let total = 0
            let amount = 0
            for ( let s = 0; s<studentList.length; s++)
            {
                const studentLessons = lessons.filter(l => l.student_id === studentList[s].student_id)
                console.log('studentLessons', studentLessons);
                studentList[s].number_of_lessons = studentLessons.length
                let debt = 0
                for ( const lesson of studentLessons )
                {
                    setHasLessons(true)
                    const debtObj = debts.find(d=> d.id === lesson.id)
                    if ( debtObj )
                    {
                        debt+= debtObj.commission
                        amount++
                    }
                        
                }
                console.log(debt);
                studentList[s].debt = debt
                total+=debt
            }
            const avatarsList = await getAvatars(studentList)
            console.log('avatarsList', avatarsList);
            

            let processedAvatars = []
            avatarsList.forEach((avatar) => {
                if (avatar === undefined) {
                    processedAvatars.push(undefined)
                } else {
                    const base64 = btoa(
                        new Uint8Array(avatar).reduce(
                            (data, byte) => data + String.fromCharCode(byte),
                            ''
                        )
                    )
                    processedAvatars.push(`data:image/png;base64,${base64}`)
                }
            })

            if (processedAvatars.length !== studentList.length) {
                console.log('avatars not match teachers length')
            }

            studentList.forEach((processedStudent, index) => {
                processedStudent.avatar = processedAvatars[index]
            })

            setTotalDebt(total)
            setDebtAmount(amount)
            console.log('setMyStudentList', studentList);
            
            setMyStudentList(studentList)

            let processedRequests = []
            suitableRequests.forEach( (request) => {
                processedRequests.push(processRequest(request,sciences, ages, targets))
            })
            setPendingRequestsList(processedRequests)
        }

        const getAvatars = async (students) => {
            const processedAvatars = await Promise.all(students.map(async (student) => {
                try {
                    const avatar = await PostService.getStudentAvatar(student.student_id);
                    console.log(avatar);
                    
                    return avatar.data;
                } catch (error) {
                    console.error(`Error fetching avatar for teacher ${student.id}:`, error);
                    return undefined; // Fallback in case of error
                }
            }));
            return processedAvatars;
        }

        getOrders()
        
    }, [])

    /*useEffect( () => {

        const getRequestStudents = async ( requests ) => {
            const processedStudents = await Promise.all(requests.map( async (request) => {
                try 
                {
                    const response = await PostService.getRequestStudentContact(request.id)
                    const obj = {
                        student_id: request.student_id,
                        name: response.data.name,
                        tg_name: response.data.telegram_login
                    }
                    return obj
                }
                catch ( error )
                {
                    console.error('getRequestStudents error',request, error)
                    return undefined
                }
                
            }))

            return processedStudents
        }

        const getOrderStudents = async ( orders ) => {
            const processedStudents = await Promise.all(orders.map( async (order) => {
                try 
                {
                    const response = await PostService.getOrderStudent(order.id)
                    const obj = {
                        student_id: order.student_id,
                        name: response.data.name,
                        tg_name: response.data.telegram_login
                    }
                    return obj
                }
                catch ( error )
                {
                    console.error('getOrderStudents error',order, error)
                    return undefined
                }
                
            }))

            return processedStudents
        }

        const getQuiz = async () => {
            

            let orderTeachers = []
            if ( teacherOrders.length === 0 )
            {
                const response = await PostService.getTeacherOrders()
                if ( response.status !== 200 )
                {
                    
                }
                const orders = response.data
                
                orderTeachers = await getOrderStudents(orders)
                setTeacherOrders(orders)
            }
            
            let requestTeachers = []
            if ( waitingRequest.length === 0 )
            {
                const response = await PostService.getAcceptedRequests()
                if ( response.status !== 200 )
                {
                    
                }
                const requests = response.data      
                console.log(requests);
                requestTeachers = await getRequestStudents(requests)
                setWaitingRequests(requests)
            }

            const response = await PostService.getTeacherQuiz()
            
            if ( response.status !== 200 )
            {
                return
            }
            console.log('quizes ::::', response.data);

            if ( response.data.length === 0 )
                return

            let processedQuizes = []
            let activeQuiz = []
            for ( const quiz of response.data )
            {
                const student = orderTeachers.find(o => o?.student_id === quiz.student_id)
                if ( student === undefined )
                    continue

                if ( quiz.teacher_answered_at !== null )
                    continue

                activeQuiz.push(true)
                const obj = {
                    id: quiz.id,
                    student_name: student.name,
                    tg_name: student.tg_name,
                    created_at: quiz.created_at
                }
                processedQuizes.push(obj)
            }
            console.log(processedQuizes);
            console.log(activeQuiz);
            if (processedQuizes.length !== 0)
                setQuizes(processedQuizes)
            if ( activeQuiz.length !== 0 )
                setModalActiveArr(activeQuiz)
        }

        //getQuiz()

    }, [quizes, modalActiveArr])*/

    return (
        <div className='teacher-main-page'>
            <TeacherPageHeader teacher={currentTeacher} avatarSrc={avatarSrc === null ? null : avatarSrc}/>
            <div className='progress-title'>Анкета заполнена на <span className='progress'>{(parseInt(currentTeacher?.payment_rating_from_profile*100/1400))}%</span></div>
            {
                true ? <StoriesCarousel isMain={false}/> : <></>
            }
            <div className='buttons-container'>
                <div className='requests-container' onClick={goRequests}>
                    <div className='requests-title' >Мои заявки</div>
                    {/*<div className='requests-counter'>1</div>*/}
                </div>
                <div className='requests-container'>
                    <div className='reports-title'>{hasLessons ? totalDebt === 0 ? `Все отчёты заполнены` : `Незаполненные отчёты:` : `Отчётов нет`}</div>
                    {
                        !hasLessons ?
                        <></>
                        :
                        <div style={{ whiteSpace: 'pre-line' }} className={totalDebt === 0 ? 'no-debt': 'has-debt'}>{`Задолженность по комиссии:\n ${totalDebt} ₽`}</div>
                    }
                </div>
            </div>
            <div className='student-title'>Мои ученики</div>
            <div style={{ paddingRight: '14px', paddingLeft: '14px' }}>
                {
                        myStudentList.length !== 0 ? 
                        <StudentList students={myStudentList} listType={'my-page'} />
                        :<div className='student-empty'>У вас пока нет учеников</div>
                }
            </div>
            
            {
                false ?
                quizes.map((quiz, index) =>
                    <ModalQuiz active={modalActiveArr[index]} setActive={onQuizSent} quizData={quiz} index={index} isStudent={false} arrLen={modalActiveArr.length}/>
                )
                : <></>
            }
            {
                true ?
                <></>
                :
                <></>//<PaymentForm token={token} />
            }
            
        </div>
    )
}

export default TeacherMainPage