import { createContext, useState } from "react";

export const AuthContext = createContext()

export const AuthProvider = ({children}) => {

    const [GSciences, setGSciences] = useState([])
    const [GStatuses, setGStatuses] = useState([])
    const [GTargets, setGTargets] = useState([])
    const [GAges, setGAges] = useState([])
    const [GFilter, setGFilter] = useState([])
    const [GOrders, setGOrders] = useState([])
    const [GTeachers, setGTeachers] = useState([])

    return <AuthContext.Provider 
        value={{
            GSciences, setGSciences,
            GStatuses, setGStatuses, 
            GTargets, setGTargets, 
            GAges, setGAges, 
            GFilter, setGFilter, 
            GOrders, setGOrders,
            GTeachers, setGTeachers
        }}>
        {children}
    </AuthContext.Provider>
}