import { useEffect, useState, useRef } from 'react'
import Select from 'react-select'

function ScienceCard( { scienceInit, scienceList, targetList, ageList, statusList, onCardReady } ) {

    const [science, setScience] = useState()
    const [target, setTarget] = useState([])
    const [age, setAge] = useState([])
    const [teacherStatus, setTeacherStatus] = useState()

    const [targetValues, setTargetValues] = useState([])
    const [ageValues, setAgeValues] = useState([])
    const [statusValue, setStatusValue] = useState()

    const [sciences, setSciences] = useState([])
    const [targets, setTargets] = useState([])
    const [ages, setAges] = useState([])
    const [statuses, setStatuses] = useState()

    const targetsRef = useRef()
    const agesRef = useRef()
    const statusesRef = useRef()

    const handleScienceChange = ( selectedOption ) => {
        if ( selectedOption !== null )
            setScience(selectedOption.value)
        targetsRef.current.clearValue()
        agesRef.current.clearValue()
        statusesRef.current.clearValue()
        if ( selectedOption !== null )
            handleCardChange()
    }

    const handleTargetChange = ( selectedOptions ) => {
        let values = []
        selectedOptions.forEach( option => values.push(option.value))
        setTarget(values)
        handleCardChange()
    }

    const handleAgeChange = ( selectedOptions ) => {
        let values = []
        selectedOptions.forEach( option => values.push(option.value))
        setAge(values)
        handleCardChange()
    }

    const handleStatusChange = ( selectedOption ) => {
        if ( selectedOption === null )
            return
        setTeacherStatus(selectedOption.value)
        handleCardChange()
    }

    const handleCardChange = () => {
        if ( science !== null && teacherStatus !== null && teacherStatus !== undefined && target.length !== 0 && age.length !== 0 )
            onCardReady(science, target, age, teacherStatus)
    }

    useEffect( () => {
        handleCardChange()
    }, [science, target, age, teacherStatus])

    useEffect( () => {
        if (science === undefined)
        {
            setScience(scienceInit?.science_id)
            if (target.length === 0)
                setTarget(scienceInit?.lesson_target_ids)
            if (age.length === 0)
                setAge(scienceInit?.age_ids)
            if (teacherStatus === undefined)
                setTeacherStatus(scienceInit?.teacher_status)
        }
    }, [scienceInit])


    useEffect( () => {
        console.log('useeffect')
        let tempSciences = []
        scienceList.forEach( sc => 
            tempSciences.push({value:sc.id, label:sc.name})
        )
        if ( sciences.length === 0)
            setSciences(tempSciences)
        
        const scienceObj = scienceList.find( sc => sc.id === science )

        if ( scienceObj === undefined )
            return

        let scTargetList = []

        scienceObj.targets.forEach( trg => {
            const targetObj = targetList.find( t => t.id === trg )
            scTargetList.push({value:targetObj.id, label:targetObj.name})
        })
        setTargets(scTargetList)

        let trgValueList = []
        target.forEach( trgId  => {
            trgValueList.push(scTargetList.find( t => t.value === trgId))
        })
        setTargetValues(trgValueList)

        let scAgeList = []
        scienceObj.ages.forEach( ag => {
            const ageObj = ageList.find( a => a.id === ag)
            scAgeList.push({value:ageObj.id, label:ageObj.name})
        })
        setAges(scAgeList)

        let ageValueList = []
        age.forEach( ageId => {
            ageValueList.push(scAgeList.find(a=> a.value === ageId))
        })
        setAgeValues(ageValueList)

        let scStatusList = []
        scienceObj.statuses.forEach( sts => {
            const statusObj = statusList.find( s => s.id === sts)
            scStatusList.push({value:statusObj.id, label:statusObj.name})
        })
        setStatuses(scStatusList)
        setStatusValue(scStatusList.find(st => st.value === teacherStatus))

    }, [scienceList, science, sciences, target, age, teacherStatus])

    return (
        <div className='science-card teacher-description'>
            <Select onChange={handleScienceChange} placeholder={'Дисциплина'} isSearchable={false} value={sciences.find(sc=> sc.value === science)}
            className='science-name select' options={sciences}
            menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
            <Select onChange={handleTargetChange} ref={targetsRef} isSearchable={false} placeholder={'Направления'} isMulti  value={targetValues}
            isClearable className='science-targets select' options={targets}
            menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
            <Select onChange={handleAgeChange} ref={agesRef} isSearchable={false} placeholder={'Возрастные группы'} isMulti  value={ageValues}
            isClearable className='science-ages select' options={ages}
            menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
            <Select onChange={handleStatusChange} ref={statusesRef} isSearchable={false} placeholder={'Статус преподавателя'}  value={statusValue}
            className='science-status select' options={statuses}
            menuPortalTarget={document.body} styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
        </div>
    )
}

export default ScienceCard