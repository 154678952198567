import '../App.css'
import { useEffect, useRef, useState } from 'react'
import PostService from '../API/PostService';
import { useNavigate } from 'react-router-dom'
const tgWebApp = window.Telegram.WebApp;

function StudentUpdatePage() {

    const [currentStudent, setCurrentStudent] = useState({})
    const [name, setName] = useState('')

    const navigate = useNavigate()
    const goStudentPage = () => navigate(`/student/me`, {replace: true})

    const BackButton = tgWebApp.BackButton
    BackButton.show()
    BackButton.onClick(goStudentPage)

    const [avatarSrc, setAvatarSrc] = useState(null)
    const fileInputRef = useRef()

    useEffect( () => {
        const getCurrentStudent = async () => {
            let response = await PostService.loginTelegram()
            response = await PostService.getCurrentStudent()

            if ( response.status !==200 )
            {
                return
            }
            setCurrentStudent(response.data)
            setName(response.data.name)

            response = await PostService.getMyStudentAvatar()
            if ( response.status !==200 )
            {
                return
            }
            const base64 = btoa(
                new Uint8Array(response.data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            )
            setAvatarSrc(`data:image/png;base64,${base64}`)
        }

        getCurrentStudent()
    }, [])

    const handleNameChanged = ( event ) => {
        setName(event.target.value)
    }

    const handleImageClick = () => {
        fileInputRef.current.click()
    }

    const handleAvatarUpload = async ( e ) => {
        console.log(e.target.files);
        if ( e.target.files.length !== 1 )
            return
        console.log(';lllllllllllllllll');
        //const image = URL.createObjectURL(e.target.files[0]).replace('blob:','')
        const image = e.target.files[0]
        if (!image.type.startsWith('image/'))
            return
        const formData = new FormData()
        formData.append('student_avatar', image)
        console.log(formData);
        const response = await PostService.uploadStudentAvatar(formData)
        if ( response.status !== 200 )
        {
            console.log(response);
            return
        }
        const reader = new FileReader();
        reader.onload = () => {
            const base64 = btoa(
                new Uint8Array(reader.result).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            )
            setAvatarSrc(`data:image/png;base64,${base64}`)
        }
        reader.readAsArrayBuffer(image);
    }

    const handleClick = async () => {
        const studObj = { "name":name }
        const response = await PostService.updateStudent(studObj, currentStudent.id)
        if ( response.status !== 200)
        {
            
        }
        goStudentPage()
    }

    return (
        <div className='student-update-page'>
            <img className="avatar-image" src={avatarSrc ? avatarSrc :"https://sun9-72.userapi.com/impg/oErJGs22nV9hEBFVaJb265b4mJWkSuQEKSfzQw/8cALmH0jVVE.jpg?size=516x304&quality=96&sign=a857802168baf4230f1521aa91b1745b&type=album"} alt='new' onClick={handleImageClick}/>
            <input
                    type="file"
                    ref={fileInputRef}
                    accept="image/gif, image/jpeg, image/png, image/heic"
                    style={{ display: 'none' }}
                    onChange={handleAvatarUpload}
            />
            <div className='name-title'>Имя</div>
            <input className='name-input' placeholder='Имя' value={name} onChange={handleNameChanged}/>
            <div className='button-save' onClick={handleClick}>Сохранить изменения</div>
        </div>
    )
}

export default StudentUpdatePage