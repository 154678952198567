import { useState } from 'react'
import '../App.css'

import Toggle from 'react-toggle'
import "react-toggle/style.css"

import TextareaAutosize from 'react-textarea-autosize'
import PostService from '../API/PostService'

const ModalQuiz = ( { active, setActive, quizData, index, isStudent, arrLen } ) => {

    //if ( quizData.student_answered_at !== null )
    //    console.log('ModalQuiz:::::::::::::::::::::::::::', quizData, index, active);
    const [isChecked, setChecked] = useState(true)
    const [price, setPrice] = useState(0)
    const [report, setReport] = useState('')
    const [tabIndex, setTabIndex] = useState(1)

    const handleNo = () => {
        setChecked(false)
        setTabIndex(3)
    }

    const handleYes = () => {
        setChecked(true)
        setTabIndex(2)
    }

    const handleButtonClicked = async () => {
        let reportObj = {}
        if ( isStudent )
            reportObj = {
                id: quizData.id,
                is_student_proceeding: isChecked,
                student_price: price,
                student_report: report
            }
        else
            reportObj = {
                id: quizData.id,
                is_teacher_proceeding: isChecked,
                teacher_price: price,
                teacher_report: report
            }
        setActive(reportObj, index)
    }

    const handlePriceChanged = ( event ) => {
        setPrice(event.target.value)
    }

    const handleReportChanged = ( event ) => {
        setReport(event.target.value)
    }

    return (
        <div className={arrLen-1 === index ? 'modal-container active' : 'modal-container'}>
            <div className='modal-quiz-student' onClick={e => e.stopPropagation()}>
                <div className={tabIndex === 1 ? 'content active-content' : 'content'}>
                    <div className='modal-header'>Договорились о первом занятии?</div>
                    {
                        isStudent ? 
                        <div className='choice-text'>{`С преподавателем: ${quizData.teacher_name}`}</div>
                        : <div className='choice-text'>{`С учеником: ${quizData.student_name} • @${quizData.tg_name}`}</div>
                    }
                    
                    <div className='button-container'>
                        <div className='button-choice' onClick={handleNo}>Нет</div>
                        <div className='button-choice' onClick={handleYes}>Да</div>
                    </div>
                </div>
                <div className={tabIndex === 2 ? 'content active-content' : 'content'}>
                    <div className='modal-header'>Итоговая стоимость</div>
                    <input type='number' onChange={handlePriceChanged} value={price} />
                    {
                        isStudent ?
                        <></>
                        :
                        <div className='date-container'>
                            <div className='modal-header'>Дата первого занятия</div>
                            <input type='date' value={quizData.created_at.split('T')[0]} onClick={e => e.stopPropagation()} disabled/>
                        </div>
                    }
                    <div className='button-container'>
                        <div className='button-send' onClick={handleButtonClicked}>Отправить</div>
                    </div>
                    
                </div>
                <div className={tabIndex === 3 ? 'content active-content' : 'content'}>
                    <div className='modal-header'>{isStudent ? 'Опишите почему' : 'Причина'}</div>
                    <TextareaAutosize onChange={handleReportChanged} value={report} />
                    <div className='button-container'>
                        <div className='button-send' onClick={handleButtonClicked}>Отправить</div>
                    </div>
                </div>
                {
                /*
                    <div>Занятия продолжатся?</div>
                                <div>Стоимость занятия</div>
                <input type='number' onChange={handlePriceChanged} value={price}/>
                <div>Дополнительная информация</div>
                <TextareaAutosize onChange={handleReportChanged} value={report} />
                <button onClick={handleButtonClicked}>Отправить отчет</button>
                */
                }
            </div>
        </div>
    )
}

export default ModalQuiz